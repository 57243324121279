import React, { Component, useEffect, useState } from 'react'

import { fetchAPIwithAuth } from '../helpers'

function withData(WrappedComponent, config) {


    return class extends Component {

        constructor(props) {
            super(props)
            this.state = {
                data: [],
                meta:[],
                page: 1
            }
        }

        componentDidMount() {
            this.fetchData()
        }

        fetchData= () => {
            
            const query = new URLSearchParams(window.location.search)

            let { endpoint, params, filterBy } = config
            
            let queryParams = {}

            if(filterBy) {
                filterBy.map( param => {
                    const paramValue = query.get(param)
                    if(paramValue) {
                        queryParams[param] = paramValue
                    }
                }) 
            }
            
            const queryString = new URLSearchParams({
                page: this.state.page,
                ...params,
                ...queryParams
            }).toString()


            fetchAPIwithAuth( endpoint + '?&' + queryString, {
                method: "get"
            }).then(res => {

                const { data, meta } = res.body

                const obj = {
                    data: data
                }

                if(typeof(meta) != 'undefined') {
                    obj['meta'] = {
                        current_page: meta?.current_page,
                        from: meta?.from,
                        last_page: meta?.last_page,
                        per_page: meta?.per_page,
                        to: meta?.to,
                        total: meta?.total                         
                    }
                }

                this.setState(obj)
            })
        }

        paginate = (page) => {
            this.setState({page: page, data:[]}, () => {
                this.fetchData()
            })
        }

        render() {

            const { data, meta } = this.state

            return (
                <>
                    <WrappedComponent data={data} paginate={this.paginate} meta={meta} {...this.props} />
                </>
            )
        }

    }

}

export default withData
