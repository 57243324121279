import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Modal, TextInput, Select, SelectItem, TextArea, MultiSelect, Dropdown, NumberInput, InlineNotification, TextInputSkeleton } from 'carbon-components-react'
import { SelectWithStores } from '../components/select';
import { fetchAPIwithAuth } from '../helpers';
import { useAuth } from '../hooks/useUser';

export default function ModalAddMaintenance({
    isModalOpen,
    closeModal
}) {

    const [isSaving, setIsSaving] = useState(false);
    const [formNotification, setformNotification] = useState(false)
    const [equipments, setEquipmennts] = useState([])
    const { user } = useAuth()

    const [form, setForm] = useState({
        equipment_id: 0,
        quantity: 1
    });

    const handleFormChange = (key, field, value) => {
        const change = { ...form }

        if (!value) {
            change[key] = field
        } else {
            change[key][field] = value
        }

        setForm(change)
    }

    const save = async () => {

        let data = {
            equipment_id: form.equipment_id,
            quantity: form.quantity
        }

        return fetchAPIwithAuth('/maintenance', {
            method: "POST",
            body: JSON.stringify(data)
        }).then(res => {

            setIsSaving(false)

            const { status, body } = res
            if (status == 200) {
                return Promise.resolve({
                    notification: {
                        kind: 'success',
                        title: `تم اضافة الى الصيانة بنجاح`
                    }
                })
            }
            return Promise.reject({
                notification: {
                    kind: 'error',
                    title: 'حدث خطأ غير متوقع.',
                    subtitle: 'المرجو اعادة المحاولة مرة اخرى'
                }
            })

        })


    }

    const fetchEquipments = (storeID) => {

        const queryString = new URLSearchParams({
            per_page: -1,
            store: storeID
        }).toString()

        fetchAPIwithAuth('/equipments?&' + queryString, {
            method: "get",
        }).then(res => {

            let equipments = []

            res.body.data.map(equipment => {
                equipments.push({
                    equipment_id: equipment.id,
                    name: equipment.name,
                })
            })

            setEquipmennts(equipments)
        })
    }

    const initialModal = () => {
        //setEquipmennts([])
        setIsSaving(false)
        setformNotification(false)
        setForm({
            equipment_id: 0,
            quantity: 1
        })
    }

    useEffect(() => {
        if (user.role == 'employee') {
            fetchEquipments(user.store_id);
        }
    }, [])

    return (
        ReactDOM.createPortal(
            <Modal
                open={isModalOpen}
                hasForm
                size="sm"
                modalHeading="ارسال للصيانة"
                modalLabel="الصيانة"
                primaryButtonDisabled={isSaving}
                primaryButtonText={isSaving ? "جاري الحفظ ..." : "اضف"}
                secondaryButtonText="الغاء"
                preventCloseOnClickOutside
                onRequestSubmit={() => {
                    setIsSaving(true)
                    save().then((res) => {
                        setformNotification(res.notification)
                        location.reload()
                    }).catch((error) => {
                        setformNotification(error.notification)
                    })
                }}
                onRequestClose={() => {
                    initialModal()
                    closeModal()
                }}
            >

                {user.role != 'employee' && (<SelectWithStores
                    onChange={(e) => fetchEquipments(e.target.value)}
                    id="equipment-store"
                    defaultValue={0}
                    selectedValue={0}
                    name="store"
                    invalidText="المرجو اختيار متجر المعدة"
                    //invalid={fieldErrors?.store}
                    labelText="متجر المعدة"
                    idKey='id'
                    textKey='name'
                    style={{ marginBottom: "1rem" }}
                />)}

                {equipments.length == 0 && user.role == 'employee' ? (
                    <TextInputSkeleton />
                ) : (
                    <Select
                        labelText="اختر المعدة"
                        style={{ marginBottom: "1rem" }}
                        onChange={(e) => {
                            handleFormChange('equipment_id', e.target.value)
                        }}
                    >
                        <SelectItem value={0} />
                        {equipments.map(equipment => (
                            <SelectItem value={equipment.equipment_id} text={equipment.name} />
                        ))}
                    </Select>

                )}


                <NumberInput
                    id="carbon-number"
                    onChange={(e) => {
                        handleFormChange('quantity', e.imaginaryTarget.value)
                    }}
                    //max={}
                    value={form.quantity}
                    label="الكمية"
                    invalidText="Number is not valid"
                    min={1}
                />

                {formNotification !== false && <InlineNotification
                    role='alert'
                    onClose={() => setformNotification(false)}
                    {...formNotification}
                />}

            </Modal>, document.body
        )
    )
}
