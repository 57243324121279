import React, { useState, useEffect, useRef } from 'react'
import {
    Button,
    ButtonSet,
    Column,
    Form,
    MultiSelect,
    Checkbox,
    NumberInput,
    Row,
    TextArea,
    TextInput,
    InlineNotification,
    TextInputSkeleton,
    ProgressIndicator,
    ProgressStep,
    SelectItem,
    Select,
    FileUploader,
    FileUploaderItem
} from 'carbon-components-react'
import {
    Add16,
    Printer16
} from "@carbon/icons-react"
import { spacing } from '@carbon/layout'

import moment from 'moment'

import EquipmentsOrderSummary from '../../components/EquipmentsOrderSummary';
import { SelectWithStores } from '../../components/select';
import { fetchAPIwithAuth } from '../../helpers';
import FormSubmitButton from '../../components/form-submit-button';
import { useHistory } from 'react-router'
import { useAuth } from '../../hooks/useUser'
import DateTimePicker from '../../components/DateTimePicker'

const SepeateReturnDate = ({
    onDateChange,
    onPeriodChange,
    equipment_id,
    selectedEquipment,
    return_date
}) => {

    const [isSeparate, setIsSeparate] = useState(!!return_date)

    return (
        <>
            <div style={{ marginBottom: "1rem" }}>
                <Checkbox labelText="تاريخ الإرجاع مستقل ؟" checked={isSeparate} id={`checked-${equipment_id}`} onChange={(isChecked) => setIsSeparate(isChecked)} />
            </div>

            {isSeparate && (
                <>
                    <Row style={{ marginBottom: spacing[4] }}>
                        <Column lg={3} style={{ marginBottom: 8 }}>

                            <Select
                                labelText="اختر الفترة"
                                onChange={(e) => onPeriodChange(e.target.value)}
                            >
                                {[{
                                    value: 'daily',
                                    name: "يومي"
                                }, {
                                    value: 'hourly',
                                    name: "بالساعة"
                                }, {
                                    value: 'weekly',
                                    name: "اسبوعي"
                                }, {
                                    value: 'monthly',
                                    name: "شهري"
                                }].map(period => (
                                    <SelectItem value={period.value} text={period.name} selected={period.value == selectedEquipment.period} />
                                ))}
                            </Select>
                        </Column>
                        <Column lg={13} style={{ marginBottom: 8 }}>
                            <DateTimePicker
                                value={selectedEquipment?.return_date}
                                onChange={(date) => {
                                    onDateChange(date)
                                }}
                                datePickerProps={{
                                    style: {
                                        marginBottom: spacing[8]
                                    }
                                }}
                                datePickerInputProps={{
                                    labelText: "تاريخ الإرجاع",
                                    style: { marginLeft: "0.5rem" }
                                }}
                            />
                        </Column>
                    </Row>
                </>
            )}
        </>
    )
}

const TenantInfo = ({
    data,
    handleFormChange,
    moveForward,
    goBack
}) => {

    const [fieldErrors, setFieldsError] = useState({})

    const [fileStatus, setfileStatus] = useState(null)

    let docsRef = useRef(null)

    const [filesToUpload, setFilesToUpload] = useState([])

    const {
        tenant_name,
        tenant_ID_Residency_number,
        tenant_phone,
        tenant_nationality,
        tenant_address,
        tenant_documents
    } = data

    const validateForm = async () => {

        let errors = {}

        if (!tenant_name) {
            errors["tenant_name"] = true
        }

        if (!tenant_phone) {
            errors['tenant_phone'] = true
        }

        if (!tenant_address) {
            errors['tenant_address'] = true
        }

        if (!tenant_nationality) {
            errors['tenant_nationality'] = true
        }

        if (!tenant_ID_Residency_number) {
            errors['tenant_ID_Residency_number'] = true
        }

        // Check if there is any errors
        if (Object.keys(errors).length > 0) {
            return Promise.reject(errors)
        }

    }

    const upload = () => {

        let fl = filesToUpload
        var i = 0;

        var myHeaders = new Headers();

        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var formdata = new FormData();


        for (i; i < fl.length; i++) {
            formdata.append("files[]", fl[i]);
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
        }

        return fetchAPIwithAuth("/media", requestOptions)

    }


    return (
        <>
            <Row style={{ marginBottom: spacing[6] }}>
                <Column>
                    <TextInput
                        id="tenant-name"
                        value={tenant_name}
                        invalid={fieldErrors?.tenant_name}
                        onChange={(e) => handleFormChange("tenant", "tenant_name", e.target.value)}
                        invalidText="هذا الحقل إجباري"
                        labelText="اسم المستأجر"
                    />
                </Column>
                <Column>
                    <TextInput
                        name="tenant-ID-Residency-number"
                        value={tenant_ID_Residency_number}
                        invalid={fieldErrors?.tenant_ID_Residency_number}
                        invalidText="هذا الحقل إجباري"
                        onChange={(e) => handleFormChange("tenant", "tenant_ID_Residency_number", e.target.value)}
                        labelText="رقم الهوية/الإقامة/جواز السفر"
                    />
                </Column>
            </Row>
            <Row style={{ marginBottom: spacing[6] }}>
                <Column>
                    <TextInput
                        name="tenant-nationality"
                        value={tenant_nationality}
                        invalid={fieldErrors?.tenant_nationality}
                        invalidText="هذا الحقل إجباري"
                        onChange={(e) => handleFormChange("tenant", "tenant_nationality", e.target.value)}
                        labelText="الجنسية"
                    />
                </Column>
                <Column>
                    <TextInput
                        name="tenant-phone"
                        value={tenant_phone}
                        invalid={fieldErrors?.tenant_phone}
                        invalidText="هذا الحقل إجباري"
                        onChange={(e) => handleFormChange("tenant", "tenant_phone", e.target.value)}
                        labelText="جوال"
                    />
                </Column>
            </Row>

            <Row style={{ marginBottom: spacing[6] }}>
                <Column>
                    <TextArea
                        name="tenant-address"
                        value={tenant_address}
                        invalid={fieldErrors?.tenant_address}
                        labelText="العنوان"
                        invalidText="هذا الحقل إجباري"
                        onChange={(e) => handleFormChange("tenant", "tenant_address", e.target.value)}
                    />
                </Column>
            </Row>

            <div className="bx--file__container" style={{ marginBottom: spacing[8] }}>

                <FileUploader
                    buttonKind="primary"
                    buttonLabel="اختر ملف(ات)"
                    filenameStatus={fileStatus}
                    accept={['jpg', 'jpeg', 'png', 'pdf', 'txt']}
                    multiple
                    //iconDescription="Clear file"
                    labelDescription="ملفات .jpg,.jpeg,.png,.pdf,.txt فقط بحجم 4 ميغا بايت أو أقل"
                    labelTitle="مرفقات"
                    onChange={(e) => setFilesToUpload(e.target.files)}
                    ref={docsRef}
                />

                {tenant_documents && tenant_documents.map(tenant_document => (<FileUploaderItem
                    name={tenant_document.original_name}
                    status="edit"
                    onDelete={() => {
                        //setcurrentFileId(null)
                        //console.log('delete fired for', currentFileId)
                    }} />
                ))
                }

            </div>

            <ButtonSet>
                <Button
                    disabled={fileStatus == 'uploading'}
                    kind='primary'
                    onClick={() => {
                        validateForm().then(async () => {

                            if (filesToUpload.length === 0) {
                                moveForward()
                                return
                            }

                            setfileStatus('uploading')

                            try {
                                upload().then(res => {
                                    docsRef.current.clearFiles()
                                    let cloneDocs = [...tenant_documents]
                                    handleFormChange('tenant', 'tenant_documents', cloneDocs.concat(res.body))
                                    setfileStatus('complete')
                                    setFilesToUpload([])
                                    moveForward()
                                })
                            } catch (e) {
                                console.log('error', error)
                            }
                        }).catch(e => {
                            setFieldsError(e)
                        })
                    }}>
                    حفظ و متابعة
                </Button>
            </ButtonSet>

        </>
    )
}

const EquipmentForm = ({
    data,
    handleFormChange,
    moveForward,
    goBack
}) => {

    const { user } = useAuth()

    const [fieldErrors, setFieldsError] = useState({})

    const selectedEquipments = data?.selectedEquipments

    const validateForm = async () => {

        let errors = {}

        if (selectedEquipments.length == 0) {
            errors["equipments"] = true
        }

        // Check if there is any errors
        if (Object.keys(errors).length > 0) {
            return Promise.reject(errors)
        }

    }

    const fetchEquipments = (storeID) => {

        const queryString = new URLSearchParams({
            per_page: -1,
            store: storeID
        }).toString()

        fetchAPIwithAuth('/equipments?&' + queryString, {
            method: "get",
        }).then(res => {

            let equipments = []

            res.body.data.map(equipment => {
                equipments.push({
                    equipment_id: equipment.id,
                    name: equipment.name,
                    cost: equipment.rent_price,
                    quantity: 1,
                    return_date: null,
                    period: null,
                    status: 'ok'
                })
            })
            handleFormChange('equipments', equipments)
        })
    }

    useEffect(() => {
        if(user.role == 'employee') {
            fetchEquipments(user.store_id)
        }
    }, [])

    return (
        <>
            <Row style={{ marginBottom: spacing[5] }}>
                {user.role != 'employee' && (
                    <Column>
                        <SelectWithStores
                            onChange={(e) => {
                                console.log(e.target.value)
                                handleFormChange('tenant', 'store_id', e.target.value)
                                fetchEquipments(e.target.value)

                            }}
                            id="tools-store"
                            defaultValue={0}
                            value={data?.tenant.store_id}
                            name="store"
                            invalidText="المرجو اختيار متجر الموظف"
                            //invalid={fieldErrors?.store}
                            labelText="المتجر"
                            idKey='id'
                            textKey='name'
                        />
                    </Column>
                )}
                <Column>
                    <MultiSelect
                        id="tools"
                        titleText="المعدات"
                        label="المعدات"
                        items={data?.equipments}
                        invalid={fieldErrors?.equipments}
                        invalidText="المرجو إختيار معدة على الأقل"
                        onChange={({ selectedItems }) => {
                            handleFormChange('selectedEquipments', selectedItems)
                        }}
                        initialSelectedItems={selectedEquipments}
                        itemToString={(item) => (item ? item.name : '')}
                    />
                </Column>
            </Row>

            {
                selectedEquipments.map(selectedEquipment => {
                    return (
                        <>
                            <h6 style={{ marginBottom: spacing[5] }}>{selectedEquipment.name}</h6>
                            <Row style={{ marginBottom: spacing[4] }}>
                                <Column lg={6} style={{ marginBottom: 8 }}>
                                    <NumberInput
                                        label="تكلفة الإيجار"
                                        onChange={(e) => {

                                            let tmpSelectedEquipment = [...selectedEquipments]
                                            let selectedEquipmentIndex = tmpSelectedEquipment.map(selectedEquipment => selectedEquipment.equipment_id).indexOf(selectedEquipment?.equipment_id)

                                            if (selectedEquipmentIndex > -1) {
                                                tmpSelectedEquipment[selectedEquipmentIndex].cost = e.imaginaryTarget.value
                                            }
                                            handleFormChange('selectedEquipments', tmpSelectedEquipment)

                                        }}
                                        value={selectedEquipment.cost}
                                    />
                                </Column>
                                <Column lg={6} style={{ marginBottom: 8 }}>
                                    <NumberInput
                                        label="عدد المعدات"
                                        onChange={(e) => {
                                            let tmpSelectedEquipment = [...selectedEquipments]
                                            let selectedEquipmentIndex = tmpSelectedEquipment.map(selectedEquipment => selectedEquipment.equipment_id).indexOf(selectedEquipment?.equipment_id)

                                            if (selectedEquipmentIndex > -1) {
                                                tmpSelectedEquipment[selectedEquipmentIndex].quantity = e.imaginaryTarget.value
                                            }
                                            handleFormChange('selectedEquipments', tmpSelectedEquipment)

                                        }}
                                        value={selectedEquipment.quantity}
                                    />
                                </Column>
                            </Row>
                            {
                                <SepeateReturnDate
                                    return_date={selectedEquipment.return_date}
                                    equipment_id={selectedEquipment.equipment_id}
                                    selectedEquipment={selectedEquipment}
                                    onPeriodChange={(data) => {
                                        let tmpSelectedEquipment = [...selectedEquipments]
                                        let selectedEquipmentIndex = tmpSelectedEquipment.map(selectedEquipment => selectedEquipment.equipment_id).indexOf(selectedEquipment?.equipment_id)

                                        if (selectedEquipmentIndex > -1) {
                                            tmpSelectedEquipment[selectedEquipmentIndex].period = data
                                        }
                                        handleFormChange('selectedEquipments', tmpSelectedEquipment)
                                    }}
                                    onDateChange={(date) => {
                                        let tmpSelectedEquipment = [...selectedEquipments]
                                        let selectedEquipmentIndex = tmpSelectedEquipment.map(selectedEquipment => selectedEquipment.equipment_id).indexOf(selectedEquipment?.equipment_id)

                                        if (selectedEquipmentIndex > -1) {
                                            tmpSelectedEquipment[selectedEquipmentIndex].return_date = date
                                        }
                                        handleFormChange('selectedEquipments', tmpSelectedEquipment)

                                    }} />
                            }

                        </>
                    )
                })
            }

            <ButtonSet>
                <Button kind='primary'
                    onClick={() => {
                        validateForm().then(() => {
                            //handleFormChange('selectedEquipments', selectedEquipments)
                            moveForward()
                        }).catch(e => {
                            setFieldsError(e)
                        })
                    }}>
                    حفظ و متابعة
                </Button>
                <Button kind='secondary'
                    onClick={() => goBack()}>
                    رجوع
                </Button>
            </ButtonSet>
        </>
    )
}

const PaymentStep = ({
    data,
    handleFormChange,
    moveForward,
    goBack,
    save
}) => {

    const history = useHistory()

    const {
        start_date,
        due_date
    } = data.dues

    const [fieldErrors, setFieldsError] = useState({})
    /**
     * Display a notification at the top of form <InlineNotification />
            * @type { }
            */
    const [formNotification, setformNotification] = useState(false)


    const validateForm = async () => {

        let errors = {}

        // Check if there is any errors
        if (Object.keys(errors).length > 0) {
            return Promise.reject(errors)
        }

    }

    return (
        <>
            <Select
                labelText="اختر الفترة"
                onChange={(e) => handleFormChange('period', e.target.value)}
                style={{ marginBottom: "1rem" }}
            >
                {[{
                    value: 'daily',
                    name: "يومي"
                }, {
                    value: 'hourly',
                    name: "بالساعة"
                }, {
                    value: 'weekly',
                    name: "اسبوعي"
                }, {
                    value: 'monthly',
                    name: "شهري"
                }].map(period => (
                    <SelectItem value={period.value} text={period.name} selected={period.value == data?.period} />
                ))}
            </Select>

            <DateTimePicker
                value={start_date}
                onChange={(date) => {
                    handleFormChange('dues', 'start_date', date)
                }}
                datePickerProps={{
                    style: {
                        marginBottom: spacing[8]
                    }
                }}
                datePickerInputProps={{
                    labelText: "تاريخ الإنشاء",
                    style: { marginLeft: "0.5rem" }
                }}
            />

            <DateTimePicker
                value={due_date}
                locale="ar"
                onChange={(date) => {
                    handleFormChange('dues', 'due_date', date)
                }}
                datePickerProps={{
                    minDate: moment([start_date], "YYYY-MM-DD").format('Y-MM-DD'),
                    style: {
                        marginBottom: spacing[8]
                    }
                }}
                datePickerInputProps={{
                    labelText: "تاريخ الاستحقاق",
                    style: { marginLeft: "0.5rem" }
                }}
            />

            {formNotification !== false && <InlineNotification
                role='alert'
                onClose={() => setformNotification(false)}
                {...formNotification}
            />
            }

            <ButtonSet>
                <Button kind='primary'
                    onClick={() => {
                        validateForm().then((res) => {
                            save().then((res) => {
                                setformNotification(res.notification)
                                setTimeout(() => {
                                    history.push('./rentals')
                                }, 1000)
                            }).catch(e => {
                                setformNotification(e.notification)
                            })
                        }).catch(e => {
                            setFieldsError(e)
                        })
                    }}>
                    حفظ و متابعة
                </Button>
                <Button kind='secondary'
                    onClick={() => goBack()}>
                    رجوع
                </Button>
            </ButtonSet>

        </>
    )
}

export default function InvoiceForm({ id }) {

    let history = useHistory()

    // if props id passed,
    // show loading indicator to ther user until resources matches id loaded from the server 
    const [isLoading, setisLoading] = useState(id ? true : false)

    const [rental, setRental] = useState({
        tenant: {
            tenant_name: '',
            tenant_ID_Residency_number: '',
            tenant_phone: '',
            tenant_nationality: '',
            tenant_address: '',
            tenant_documents: [],
            store_id: 0
        },
        equipments: [],
        selectedEquipments: [],
        period: 'daily',
        dues: {
            start_date: moment().format('Y-MM-DD hh:mm:ss'),
            due_date: '',
            paid_amount: 0
        }

    })

    const handleFormChange = (key, field, value) => {
        const change = { ...rental }
        if (typeof (value) == 'undefined') {
            change[key] = field
        } else {
            change[key][field] = value
        }
        setRental(change)
    }

    const [step, updateStep] = useState(0)

    const moveForward = () => updateStep(step + 1)
    const goBack = () => updateStep(step - 1)

    const saveInvoice = async () => {
        return fetchAPIwithAuth('/orders' + (id ? '/' + id : ''), {
            method: id ? "PATCH" : "POST",
            body: JSON.stringify({
                ...rental.tenant,
                store_id: rental.store_id,
                equipments: rental.selectedEquipments,
                due_date: rental.dues.due_date,
                paid_amount: rental.dues.due_date,
                effective_date: rental.dues.start_date,
                period: rental.period,
                store_id: rental.tenant.store_id
            })
        }).then(res => {
            const { status, body } = res
            return Promise.resolve({
                invoice: body,
                notification: {
                    kind: 'success',
                    title: `تم إضافة الطلب بنجاح`
                }
            })
        }).catch(() => {
            return Promise.reject({
                notification: {
                    kind: 'error',
                    title: 'حدث خطأ غير متوقع.',
                    subtitle: 'المرجو اعادة المحاولة مرة اخرى'
                }
            })
        })
    }

    useEffect(() => {
        // if id is passed load the tool information
        if (id) {
            fetchAPIwithAuth('/orders/' + id, {
                method: "get"
            }).then(res => {
                //setinvoice(res.body)
                setisLoading(false)
            })
        }
        return
    }, [])

    if (isLoading) {
        return (
            <Column lg={6} sm={4}>
                <TextInputSkeleton />
            </Column>
        )
    }

    return (
        <Row>
            <Column lg={3}>
                <ProgressIndicator
                    currentIndex={step}
                    vertical
                    style={{ marginBottom: spacing[8] }}
                    spaceEqually={true}>
                    <ProgressStep label={'معلومات المستأجر'} />
                    <ProgressStep label={'المعدات'} />
                    <ProgressStep label={'المستحقات'} />
                </ProgressIndicator>
            </Column>
            <Column lg={9}>
                <Form>
                    {step == 0 && <TenantInfo data={rental.tenant} handleFormChange={handleFormChange} moveForward={moveForward} goBack={goBack} />}
                    {step == 1 && <EquipmentForm data={rental} handleFormChange={handleFormChange} moveForward={moveForward} goBack={goBack} />}
                    {step == 2 && <PaymentStep save={saveInvoice} data={rental} handleFormChange={handleFormChange} moveForward={moveForward} goBack={goBack} />}

                </Form>
            </Column>
            {step == 2 &&
                <Column lg={4}>
                    <EquipmentsOrderSummary equipments={rental?.equipments} dueDate={rental.dues.due_date} startDate={rental.dues.start_date} paidAmount={rental.dues.paid_amount} />
                </Column>
            }
        </Row>
    )
}
