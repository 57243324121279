import React from 'react'

import { Tile, Grid, Column, Row, AspectRatio, Pagination } from 'carbon-components-react'

import AppBar from '../../components/AppBar'
import ToolsTable from '../../components/ToolsTable'
import { useLocation, useParams } from 'react-router';
import ToolForm from '../../partial/forms/tool';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Tools() {

    let query = useQuery();

    let action = query.get('action')
    const toolId = query.get('id')

    if(toolId) {
        action = 'edit';
    }

    let pageTitle = "المعدات";

    if(action == 'add') {
        pageTitle = 'اضافة معدة'
    }else if( action == 'edit') {
        pageTitle = 'تعديل'
    }

    return (
        <>
            <AppBar title={pageTitle} />

            <div className="pt-7"></div>

            {!toolId && !action && <ToolsTable />}
            
            {
                (action == 'edit' || action == 'add') && (
                    <ToolForm id={toolId}/>
                )
            }
            
        </>

    )
}
