import React, { useEffect, useState } from 'react'
import { Modal, TableContainer, Table, TableHead, TableRow, TableHeader, TableBody, DataTable, TableCell, TableSelectAll, TableSelectRow, InlineNotification, TextInputSkeleton, } from 'carbon-components-react'
import ReactDOM from 'react-dom';
import { fetchAPIwithAuth } from '../helpers';
import DateTimePicker from '../components/DateTimePicker';
import moment from 'moment'

const TABLE_HEADER = [
    {
        key: 'checkbox',
        header: ''
    },
    {
        key: 'id',
        header: '#'
    },
    {
        key: 'name',
        header: 'المعدة',
    },
    {
        key: 'quantity',
        header: 'الكمية',
    },
    {
        key: 'start_date',
        header: 'تاريخ النفاذ',
    },
    {
        key: 'return_date',
        header: 'تاريخ الإرجاع',
    },
    {
        key: 'status',
        header: 'الحالة',
    }
]

export default function EditOrderModal({
    orderId,
    isModalOpen,
    closeModal
}) {

    const [isSaving, setIsSaving] = useState(false);
    const [formNotification, setformNotification] = useState(false)
    const [order, setOrder] = useState();
    const [returnedEquipments, setReturnedEquipments] = useState([])

    useEffect(() => {

        if (orderId) {
            fetchAPIwithAuth('/orders/' + orderId, {
                method: "get",
            }).then(res => {
                setOrder(res.body.data)
            })
        }

        return;

    }, [orderId])

    const handleFormChange = (key, field, value) => {
        const change = { ...order }

        if (typeof (value) == 'undefined') {
            change[key] = field
        } else {
            change[key][field] = value
        }

        setOrder(change)
    }

    const saveOrder = async () => {
        let data = {
            due_date: order?.due_date,
            returned_equipments: returnedEquipments
        }

        return fetchAPIwithAuth('/orders/' + orderId, {
            method: "PATCH",
            body: JSON.stringify(data)
        }).then(res => {

            setIsSaving(false)

            const { status, body } = res

            // Resource updated successfully
            if (status == 200) {
                return Promise.resolve({
                    tool: body,
                    notification: {
                        kind: 'success',
                        title: `تم تحديث المعطيات بنجاح`
                    }
                })
            }

            return Promise.reject({
                notification: {
                    kind: 'error',
                    title: 'حدث خطأ غير متوقع.',
                    subtitle: 'المرجو اعادة المحاولة مرة اخرى'
                }
            })

        })

    }


    return (
        ReactDOM.createPortal(<Modal
            open={isModalOpen}
            modalHeading="تعديل الطلب"
            modalLabel="الطلبات"
            secondaryButtonText="الغاء"
            hasForm
            preventCloseOnClickOutside
            size='md'
            onRequestClose={() => {
                setIsSaving(false)
                setformNotification(false)
                closeModal()
                //setOrder()
            }}
            onRequestSubmit={() => {
                setIsSaving(true)
                saveOrder().then((res) => {
                    setformNotification(res.notification)
                    setTimeout(() => {
                        //location.reload()
                    }, 800)
                }).catch((error) => {
                    setformNotification(error.notification)
                })
            }}
            primaryButtonDisabled={isSaving}
            primaryButtonText={isSaving ? "جاري الحفظ ..." : "حفظ الطلب"}>
            <>
                {order ?
                    <DateTimePicker
                        value={order?.due_date}
                        locale="ar"
                        onChange={(date) => {
                            handleFormChange('due_date', date)
                        }}
                        datePickerProps={{
                            minDate: moment([order?.start_date], "YYYY-MM-DD").format('Y-MM-DD'),
                            style: {
                                marginBottom: "1rem"
                            }
                        }}
                        datePickerInputProps={{
                            labelText: "تاريخ الاستحقاق",
                            style: { marginLeft: "0.5rem" }
                        }}
                    /> : <TextInputSkeleton />}

                <DataTable rows={order?.equipments ? order.equipments : []} headers={TABLE_HEADER}>
                    {({
                        rows,
                        headers,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        getSelectionProps,
                        getTableContainerProps,
                    }) => (
                        <TableContainer
                            title="المعدات"
                            description="إختر المعدات التي تم ارجاعها"
                            {...getTableContainerProps()}>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        <TableSelectAll {...getSelectionProps()} />
                                        {headers.map((header) => (
                                            <TableHeader
                                                id={header.key}
                                                key={header.key}
                                                {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => {
                                        return (
                                            <TableRow key={row.id} {...getRowProps({ row })} >
                                                <TableSelectRow
                                                    {...getSelectionProps({ row })}
                                                    checked={returnedEquipments.includes(row.cells[1].value)}
                                                    onSelect={(evt) => {
                                                        if (returnedEquipments.includes(row.cells[1].value)) {
                                                            setReturnedEquipments([...returnedEquipments.slice(0, returnedEquipments.indexOf(row.cells[1].value)), ...returnedEquipments.slice(returnedEquipments.indexOf(row.cells[1].value) + 1)])
                                                        } else {
                                                            setReturnedEquipments([...returnedEquipments, row.cells[1].value])
                                                        }
                                                    }}
                                                    disabled={row.cells[row.cells.length - 1].value == 'returned'}

                                                />
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <TableCell key={cell.id}>

                                                            {cell.info.header == 'status' ? (cell.value == 'returned' ? 'تم ارجاع' : 'مستأجرة') : cell.value}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DataTable>
                {formNotification !== false && <InlineNotification
                    role='alert'
                    onClose={() => setformNotification(false)}
                    {...formNotification}
                />
                }
            </>

        </Modal>, document.body)
    )
}
