import { useState, createContext, useContext, useEffect } from "react"
import { useLocation, useHistory } from "react-router-dom";

import { API } from '../const'

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const auth = useAuthProvider();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
    return useContext(AuthContext);
};

const useAuthProvider = () => {

    const [user, setuser] = useState(false)
    const location = useLocation()
    const histroy = useHistory()

    const signIn = async(email, password) => {

        return fetch(API + '/auth', {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({ email, password })
        }).then(res => {

            if (res.status === 201) {
                return res.json()
            }

        }).then(data => {
            localStorage.setItem('token', data.token)
            fetchUser()
        })
    }

    const signOut = () => {
        setuser({isLogged: false})
        localStorage.removeItem('token')
    }

    function fetchUser() {

        const headers = {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            "accept": "application/json"
        }

        try {
            fetch(API + '/account', {
                headers: headers
            }).then(res => res.json())
                .then(data => {
                    setuser(data)
                }).catch(e => {
                    
                })

        } catch (error) {
            
        }

    }

    useEffect(() => {

        if (['/login', '/logout'].includes(location.pathname)) {
            return;
        }

        fetchUser();

        return () => fetchUser();
    }, []);

    return {
        user,
        signIn,
        signOut
    }

}