import './App.scss';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";

import Login from './pages/Login'
import Overview from './pages/dashboard/Overview'
import Rentals from './pages/dashboard/rentals'
import Tools from './pages/dashboard/Tools';
import Stores from './pages/dashboard/stores';
import AddInvoice from './pages/dashboard/AddInvoice';
import Settings from './pages/dashboard/Settings';
import DashLayout from './components/DashLayout';
import { useAuth } from './hooks/useUser';
import { Loading, ToastNotification } from 'carbon-components-react';
import Employees from './pages/dashboard/employees';
import InvoicePrint from './pages/dashboard/invoice-print';
import Logout from './pages/logout';
import RentDetails from './pages/dashboard/rent-details';
import Maintenance from './pages/dashboard/maintenance';
import ReceiptPrint from './pages/dashboard/invoice-receipt';
import Payments from './pages/dashboard/payments';
import PaymentReceipt from './pages/dashboard/payment-receipt';

const routes = [
  {
    path: "/",
    exact: true,
    main: () => <DashLayout><Overview /></DashLayout>
  },
  {
    path: "/dashboard/rentals",
    exact: true,
    main: () => <DashLayout><Rentals /></DashLayout>
  },
  {
    path: "/dashboard/tools",
    exact: true,
    main: () => <DashLayout><Tools /></DashLayout>
  },
  {
    path: "/dashboard/stores",
    exact: true,
    main: () => <DashLayout><Stores /></DashLayout>
  },
  {
    path: "/dashboard/employees",
    exact: true,
    main: () => <DashLayout><Employees /></DashLayout>
  },
  {
    path: "/dashboard/maintenance",
    exact: true,
    main: () => <DashLayout><Maintenance /></DashLayout>
  },
  {
    path: "/dashboard/payments",
    exact: true,
    main: () => <DashLayout><Payments /></DashLayout>
  },
  {
    path: "/dashboard/rent-details",
    exact: true,
    main: () => <DashLayout><RentDetails /></DashLayout>
  },
  {
    path: "/dashboard/settings",
    exact: true,
    main: () => <DashLayout><Settings /></DashLayout>
  },
  {
    path: "/dashboard/invoice-print",
    exact: true,
    main: () => <InvoicePrint />
  },
  {
    path: "/dashboard/receipt-print",
    exact: true,
    main: () => <ReceiptPrint />
  },
  {
    path: "/dashboard/payment-receipt",
    exact: true,
    main: () => <PaymentReceipt />
  },
  {
    path: "/dashboard/add-invoice",
    exact: true,
    main: () => <DashLayout><AddInvoice /></DashLayout>
  }
];

function App() {

  const { user } = useAuth()

  // User initial value is false which means that the request from the server not established yet
  if (!user) {
    return <Loading description="جاري التحميل ..." />
  }

  if (!user?.isLoggedIn) {
    return <Route path="/" exact={true} children={<Login />} />
  }

  return (
    <>
      <Switch>
        <Route
          path={'/logout'}
          exact
        >
          <Logout />
        </Route>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route?.exact}
            children={<route.main />}
          />
        ))}
      </Switch>
    </>
  );
}

export default App;
