
import React from "react";

import {
    DataTable, TableContainer, Table, TableHead, TableRow, TableHeader, TableBody, TableCell, TableToolbar, TableToolbarContent, TableToolbarSearch, TableToolbarMenu,
    TableToolbarAction, Button, Grid, OverflowMenu, OverflowMenuItem, Tag, Pagination, DataTableSkeleton,
} from "carbon-components-react";
import { Add16 } from "@carbon/icons-react";
import { Link, useHistory } from "react-router-dom";

import { paginationProps } from "../helpers";
import withData from "./withData";

const TABLE_HEADER = [
    {
        key: 'id',
        header: '#'
    },
    {
        key: 'name',
        header: 'اسم  الموظف',
    },
    {
        key: 'email',
        header: 'البريد الالكتروني',
    },
    {
        key: 'action',
        header: null
    }
]

function EmployeesTable({ ...props }) {

    const { data, paginate, meta } = props

    let history = useHistory();

    const renderPagination = () => {
        return (
            <Pagination
                onChange={(e) => { paginate(e.page) }}
                {...paginationProps(meta)}
            />
        )
    }

    return (
        <>
            <DataTable rows={Array.isArray(data) ? data : []} headers={TABLE_HEADER}>
                {({ rows, headers, getHeaderProps, getTableProps, onInputChange }) => (
                    <TableContainer>
                        <TableToolbar>
                            <TableToolbarContent>
                                <TableToolbarSearch expanded placeholder="بحث ..." onChange={onInputChange} />
                                <Button onClick={() => history.push('/dashboard/employees?action=add')} renderIcon={Add16}>
                                    أضف موظف
                                </Button>

                            </TableToolbarContent>
                        </TableToolbar>

                        {
                            data.length === 0 ?
                                <DataTableSkeleton headers={headers} showHeader={false} showToolbar={false} columnCount={headers.length} />
                                : (
                                    <Table {...getTableProps()}>
                                        <TableHead>
                                            <TableRow>
                                                {headers.map((header) => (
                                                    <TableHeader {...getHeaderProps({ header })}>
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow key={row.id}>
                                                    {row.cells.map((cell) => {
                                                        if (cell.info.header == 'action') {

                                                            return (
                                                                <TableCell key={cell.id}>
                                                                    <OverflowMenu>
                                                                        <OverflowMenuItem onClick={() => history.push('/dashboard/employees?id=' + row.id)} itemText="تعديل" />
                                                                        <OverflowMenuItem hasDivider isDelete itemText="حذف" />
                                                                    </OverflowMenu>
                                                                </TableCell>
                                                            )
                                                        } else if (cell.info.header == 'image') {
                                                            return (
                                                                <TableCell key={cell.id}>
                                                                    <div style={{ paddingTop: 8, paddingBottom: 8 }}>
                                                                        <img src={cell.value} alt="" style={{ width: 56, height: 56, objectFit: "cover", borderRadius: 4 }} />
                                                                    </div>
                                                                </TableCell>
                                                            )
                                                        } else {
                                                            return (
                                                                <TableCell key={cell.id}>{cell.value}</TableCell>
                                                            )
                                                        }
                                                    })}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )
                        }
                    </TableContainer>
                )}
            </DataTable>
            {renderPagination()}
        </>
    )
}

export default withData(EmployeesTable, {
    endpoint: '/user'
})
