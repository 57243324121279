
import React from "react";

import {
    DataTable, TableContainer, Table, TableHead, TableRow, TableHeader, TableBody, TableCell, TableToolbar, TableToolbarContent, TableToolbarSearch,
    Button, OverflowMenu, OverflowMenuItem, Pagination, DataTableSkeleton,
} from "carbon-components-react";

import { Add16 } from "@carbon/icons-react";

import { useHistory } from "react-router-dom";
import { paginationProps } from "../helpers";
import withData from "./withData";

const TABLE_HEADER = [
    {
        key: 'id',
        header: '#',
    },
    {
        key: 'name',
        header: 'اسم  المتجر',
    },
    {
        key: 'action',
        header: null,
    }
]

function StoresTable({ ...props }) {

    let { data, paginate, meta } = props

    let history = useHistory();

    const renderPagination = () => {
        return (
            <Pagination
                onChange={(e) => { paginate(e.page) }}
                {...paginationProps(meta)}
            />
        )
    }

    return (
        <>
            <DataTable rows={data} headers={TABLE_HEADER}>
                {({ rows, headers, getHeaderProps, getTableProps, onInputChange }) => (
                    <TableContainer>
                        <TableToolbar>
                            <TableToolbarContent>
                                <TableToolbarSearch expanded placeholder="بحث ..." onChange={onInputChange} />
                                <Button onClick={() => history.push('/dashboard/stores?action=add')} renderIcon={Add16}>
                                    أضف متجر
                                </Button>
                            </TableToolbarContent>
                        </TableToolbar>
                        {
                            data.length === 0 ?
                                <DataTableSkeleton headers={headers} showHeader={false} showToolbar={false} columnCount={headers.length} /> :
                                (
                                    <Table {...getTableProps()}>
                                        <TableHead>
                                            <TableRow>
                                                {headers.map((header) => (
                                                    <TableHeader {...getHeaderProps({ header })}>
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow key={row.id}>
                                                    {row.cells.map((cell) => {
                                                        if (cell.info.header == 'action') {

                                                            return (
                                                                <TableCell key={cell.id}>
                                                                    <OverflowMenu>
                                                                        <OverflowMenuItem onClick={() => history.push('/dashboard/stores?id=' + row.id)} itemText="تعديل" />
                                                                        <OverflowMenuItem hasDivider isDelete itemText="حذف" />
                                                                    </OverflowMenu>
                                                                </TableCell>
                                                            )
                                                        }
                                                        return (
                                                            <TableCell key={cell.id}>{cell.value}</TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )
                        }
                    </TableContainer>
                )}
            </DataTable>
            {renderPagination()}
        </>
    )
}

export default withData(StoresTable, {
    endpoint: '/store'
})
