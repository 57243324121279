import React, {useEffect, useState} from 'react'
import moment from 'moment';
import { fetchAPIwithAuth } from '../helpers';
import { TextInputSkeleton } from 'carbon-components-react';


export default function EquipmentsOrderSummary({
    equipments,
    paidAmount = 0,
    dueDate,
    startDate,
}) {

    const [taxRate, settaxrate] = useState(false)

    useEffect(() => {

        fetchAPIwithAuth('/settings', {
            method: "get"
        }).then((res) => {
            const {body} = res
            console.log(body)
            settaxrate(body.tax_rate)
        })

    }, [])

    if(taxRate === false) {
        return <TextInputSkeleton/>
    }

    let equipmentsCost = 0
    const currentDate = moment([startDate], "YYYY-MM-DD hh:mm");

    

    equipments.map(equipment => {
        
        let period = 'days';
        console.log(equipment.period)
        if(equipment?.period == 'hourly') {
            period = 'hours'
        }else if(equipment?.period == 'weekly') {
            period = 'weeks'
        }else if(equipment?.period == 'monthly')  {
            period = 'months'
        }

        const duration = dueDate ? moment([dueDate], "YYYY-MM-DD hh:mm").diff(currentDate, period) : null
        let equipmentRentduration = equipment.return_date ? moment([equipment.return_date], "YYYY-MM-DD hh:mm").diff(currentDate, period) : duration

        console.log(period,equipmentRentduration)

        if(equipmentRentduration) {
            const equipmentCost = (equipment.rent_price * equipment.quantity) * equipmentRentduration
            equipmentsCost += equipmentCost
            equipment.total_cost = equipmentCost    
        }

    })

    const totalCost = equipmentsCost + (equipmentsCost * (taxRate / 100))

    return (
        <div class="bx--order-summary">
            <section class="bx--order-header">
                <p class="bx--order-header-title pb-3">ملخص الطلب</p>
            </section>
            <ul class="bx--order-list">
                {
                    Array.isArray(equipments) && equipments.map(equipment => {

                        return (
                            <li class="bx--order-category">
                                <p class="bx--order-category-title">اسم تجريبي</p>
                                <ul>
                                    <li class="bx--order-item">
                                        <p class="bx--order-detail">ايجار اليومي</p>
                                        <p class="bx--order-price">{equipment.rent_price}</p>
                                    </li>
                                    <li class="bx--order-item">
                                        <p class="bx--order-detail">الكمية</p>
                                        <p class="bx--order-price">{equipment.quantity}</p>
                                    </li>
                                    {equipment.return_date && (<li class="bx--order-item">
                                        <p class="bx--order-detail">تاريخ الإرجاع</p>
                                        <p class="bx--order-price">{equipment.return_date}</p>
                                    </li>)
                                    }
                                    {dueDate && <li class="bx--order-item">
                                        <p class="bx--order-detail">الثمن الإجمالي</p>
                                        <p class="bx--order-price">{equipment.total_cost}</p>
                                    </li>}
                                </ul>
                            </li>
                        )
                    })
                }


            </ul>
            <section class="bx--order-total-container">
                <div class="bx--order-total">
                    <p class="bx--order-total-text">تاريخ الإنشاء</p>
                    <p class="bx--order-total-price">
                        {startDate ? startDate : '-'}
                    </p>
                </div>
                <div class="bx--order-total">
                    <p class="bx--order-total-text">تاريخ الإستحقاق</p>
                    <p class="bx--order-total-price">
                        {dueDate ? dueDate : '-'}
                    </p>
                </div>

                {dueDate && <div class="bx--order-total">
                    <p class="bx--order-total-text">إجمالي المبلغ</p>
                    <p class="bx--order-total-price">
                        {equipmentsCost}
                        <span>مقدر</span>
                    </p>
                </div>}
                <div class="bx--order-total">
                    <p class="bx--order-total-text">الضريبة</p>
                    <p class="bx--order-total-price">{taxRate}%</p>
                </div>
                {dueDate && <div class="bx--order-total">
                    <p class="bx--order-total-text">المجموع المستحق الآن</p>
                    <p class="bx--order-total-price">
                        {totalCost}
                        <span>مقدر</span>
                    </p>
                </div>}
            </section>
        </div>

    )
}
