import React, { useEffect, useState } from 'react'

import { Column, Loading, Row, TextInputSkeleton } from 'carbon-components-react'

import { spacing } from '@carbon/layout'
import { useParams } from 'react-router'
import { useQuery } from '../../hooks/useQuery'
import { fetchAPIwithAuth } from '../../helpers'
import Moment from 'react-moment'
import EquipmentsOrderSummary from '../../components/EquipmentsOrderSummary'
var moment = require('moment-hijri');
moment.locale('ar-ma');// 2- Set the global locale to `ar-SA`


export default function ReceiptPrint() {

    let query = useQuery();
    const invoiceId = query.get('id')

    const [data, setdata] = useState([])

    useEffect(() => {
        if (invoiceId) {
            fetchAPIwithAuth('/orders/' + invoiceId, {
                method: "get"
            }).then(res => {
                setdata(res.body.data)
            }).then(() => {
                window.print()
            })
        }
    }, [])

    if (data.length == 0) {
        return (
            <Loading />
        )
    }

    return (
        <div id="invoice-receipt" style={{ color: "#3b3c6c", width: 700, margin: "0 auto" }}>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h3 style={{ color: "#f78d8d" }}>{data.id}</h3>
            </div>

            <p>الإسم : {data.tenant_name}</p>

            <div style={{ border: "2px #3b3c6c solid", padding: "1rem", marginTop: 16, borderRadius: 16, fontWeight: "bold" }}>
                <div style={{ marginBottom: "1.75rem" }}>

                    <div class="bx--order-summary" style={{width: "100%"}}>
                        <section class="bx--order-header">
                            <p class="bx--order-header-title pb-3">ملخص الطلب</p>
                        </section>
                        <ul class="bx--order-list">
                            {
                                Array.isArray(data?.equipments) && data?.equipments.map(equipment => {

                                    return (
                                        <li class="bx--order-category">
                                            <p class="bx--order-category-title">اسم تجريبي</p>
                                            <ul>
                                                <li class="bx--order-item">
                                                    <p class="bx--order-detail">الكمية</p>
                                                    <p class="bx--order-price">{equipment.quantity}</p>
                                                </li>
                                                {equipment.return_date && (<li class="bx--order-item">
                                                    <p class="bx--order-detail">تاريخ الإرجاع</p>
                                                    <p class="bx--order-price"><Moment format='YY/MM/DD'>{equipment.return_date}</Moment></p>
                                                </li>)
                                                }
                                            </ul>
                                        </li>
                                    )
                                })
                            }


                        </ul>
                        <section class="bx--order-total-container">
                            <div class="bx--order-total">
                                <p class="bx--order-total-text">تاريخ الإنشاء</p>
                                <p class="bx--order-total-price">
                                    {data.effective_date ? <Moment format='YY/MM/DD'>{data.effective_date}</Moment>  : '-'}
                                </p>
                            </div>
                            <div class="bx--order-total">
                                <p class="bx--order-total-text">تاريخ الإستحقاق</p>
                                <p class="bx--order-total-price">
                                    {data.due_date ? <Moment format='YY/MM/DD'>{data.due_date}</Moment> : '-'}
                                </p>
                            </div>
                            <div class="bx--order-total">
                                <p class="bx--order-total-text">الضريبة</p>
                                <p class="bx--order-total-price">{data.tax_rate}%</p>
                            </div>
                            <div class="bx--order-total">
                                <p class="bx--order-total-text">المبلغ المدفوع</p>
                                <p class="bx--order-total-price">
                                    {data.paid_amount ? data.paid_amount : "0.00"} ر.س
                                </p>
                            </div>
                            <div class="bx--order-total">
                                <p class="bx--order-total-text">إجمالي المبلغ</p>
                                <p class="bx--order-total-price">
                                    {data.amount} ر.س
                                </p>
                            </div>
                        </section>
                    </div>

                </div>
            </div>

        </div>
    )
}
