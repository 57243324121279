
import React, { useState } from "react";
import ReactDOM from 'react-dom';

import {
    StructuredListBody, StructuredListCell, StructuredListRow, StructuredListWrapper, DataTable, TableContainer, Table, TableHead, TableRow, TableHeader, TableBody, TableCell, TableToolbar, TableToolbarContent, TableToolbarSearch, TableToolbarMenu,
    TableToolbarAction, Button, Grid, DataTableSkeleton, Pagination, OverflowMenu, OverflowMenuItem, TableExpandedRow, TableExpandHeader, TableExpandRow, Row, Column, Modal, TextInput, Tag, NumberInput, Toggle, Form
} from "carbon-components-react";

import Moment from 'react-moment';

import { Add16, ErrorFilled16, CheckmarkFilled16 } from '@carbon/icons-react'
import { Link, useHistory } from "react-router-dom";
import withData from "./withData";
import { paginationProps, fetchAPIwithAuth } from "../helpers";

import 'moment/locale/ar-ma';
import EditOrderModal from "../partial/EditOrderModal";
import ModalAddPayment from "../partial/ModalAddPayment";

const TABLE_HEADER = [
    {
        key: 'id',
        header: '#',
    },
    {
        key: 'amount',
        header: 'اجمالي المبلغ',
    },
    {
        key: 'paid_amount',
        header: 'المبلغ المدفوع',
    },
    {
        key: 'employee_name',
        header: 'الموظف',
    },
    {
        key: 'effective_date',
        header: 'تاريخ النفاذ',
    },
    {
        key: 'due_date',
        header: 'تاريخ الإستحقاق',
    },
    {
        key: 'status',
        header: '',
    },
    {
        key: 'action',
        header: null,
    }
]

function RentalsTable({ ...props }) {

    const [isEditFormOpened, setIsEditFormOpened] = useState(false)
    const [isَAddPaymentOpened, setIsAddPaymentOpened] = useState(false)
    const [orderId, setOrderId] = useState(null)

    const history = useHistory()

    const { data, paginate, meta } = props

    const deleteOrder = (id) => {
        if (window.confirm('هل أنت واثق؟ لا يمكنك التراجع عن هذا الإجراء بعد ذلك.')) {
            fetchAPIwithAuth(`/orders/${id}`, {
                method: 'DELETE',
            }).then(() => window.location.reload()).catch((e) => {
                alert('حدث خطأ المرجو اعادة المحاولة')
            })
        }
    }

    const cancelOrder = (id) => {
        if (window.confirm('هل أنت واثق؟ لا يمكنك التراجع عن هذا الإجراء بعد ذلك.')) {
            fetchAPIwithAuth(`/orders/${id}/cancel`, {
                method: 'POST',
            }).then(() =>
                window.location.reload()
            ).catch((e) => {
                alert('حدث خطأ المرجو اعادة المحاولة')
            })
        }
    }

    const renderPagination = () => {
        return (
            <Pagination
                onChange={(e) => { paginate(e.page) }}
                {...paginationProps(meta)}
            />
        )
    }

    return (
        <>
            <ModalAddPayment orderId={orderId} isModalOpen={isَAddPaymentOpened} closeModal={() => setIsAddPaymentOpened(false)} />
            <EditOrderModal orderId={orderId} isModalOpen={isEditFormOpened} closeModal={() => setIsEditFormOpened(false)} />
            <DataTable rows={Array.isArray(data) ? data : []} headers={TABLE_HEADER}>
                {({ rows, headers, getHeaderProps, getTableProps, getRowProps, onInputChange }) => (

                    <TableContainer title="آخر الطلبات" description="آخر عمليات كراء المعدات">

                        <TableToolbar>
                            <TableToolbarContent>
                                <TableToolbarSearch expanded placeholder="بحث ..." onChange={onInputChange} />
                                <Button renderIcon={Add16} onClick={() => history.push('/dashboard/rentals?action=add')}>
                                    إيجار جديد
                                </Button>
                            </TableToolbarContent>
                        </TableToolbar>

                        {
                            data.length == 0 && meta?.total == undefined ? (
                                <>
                                    {<DataTableSkeleton headers={headers} showHeader={false} showToolbar={false} columnCount={headers.length} />}
                                </>
                            ) : <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        <TableExpandHeader />
                                        {headers.map((header) => (
                                            <TableHeader {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, key) => (
                                        <React.Fragment>
                                            <TableExpandRow {...getRowProps({ row })}>
                                                {row.cells.map((cell) => {
                                                    if (cell.info.header == 'action') {

                                                        return (
                                                            <TableCell key={cell.id}>
                                                                <OverflowMenu>
                                                                    <OverflowMenuItem onClick={() => window.open('/dashboard/invoice-print?id=' + row.id)} itemText="استخراج السند" />
                                                                    <OverflowMenuItem onClick={() => window.open('/dashboard/receipt-print?id=' + row.id)} itemText="استخراج الفاتورة" />
                                                                    <OverflowMenuItem
                                                                        itemText="تعديل الطلب"
                                                                        onClick={() => {
                                                                            setOrderId(row.id)
                                                                            setIsEditFormOpened(true)
                                                                        }}
                                                                    />
                                                                    <OverflowMenuItem
                                                                        itemText="أضف دفعة"
                                                                        onClick={() => {
                                                                            setOrderId(row.id)
                                                                            setIsAddPaymentOpened(true)
                                                                        }}
                                                                    />
                                                                    <OverflowMenuItem onClick={() => window.open('/dashboard/payments?order_id=' + row.id)} itemText="عرض الدفعات" />
                                                                    <OverflowMenuItem onClick={() => cancelOrder(row.id)} hasDivider itemText="إلغاء الطلب" />
                                                                    <OverflowMenuItem onClick={() => deleteOrder(row.id)} isDelete itemText="حذف الطلب" />

                                                                </OverflowMenu>
                                                            </TableCell>
                                                        )
                                                    }

                                                    if (cell.info.header == "status") {
                                                        return (
                                                            <TableCell key={cell.id}>
                                                                <Tag type={cell.value == 'canceled' ? 'red' : cell.value == 'paid' ? 'green' : 'orange'}>
                                                                    {cell.value == 'paid' && 'مدفوع'}
                                                                    {cell.value == 'unpaid' && 'غير مدفوع'}
                                                                    {cell.value == 'canceled' && 'ملغي'}
                                                                </Tag>
                                                            </TableCell>
                                                        )
                                                    }

                                                    if (cell.info.header == "due_date") {
                                                        return (
                                                            <TableCell key={cell.id}>
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    {cell.value ? <Moment local="ar" format="ll" style={{ marginLeft: "0.5rem" }}>{cell.value}</Moment> : "—"}
                                                                </div>
                                                            </TableCell>
                                                        )
                                                    }

                                                    return (
                                                        <TableCell key={cell.id}>
                                                            {['effective_date'].includes(cell.info.header) ? <Moment local="ar" format="ll">{cell.value}</Moment> : cell.value ? cell.value : "—"}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableExpandRow>
                                            <TableExpandedRow
                                                colSpan={TABLE_HEADER.length + 1}
                                            >
                                                <Grid style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                                    <Row>
                                                        <Column>
                                                            <h6>معلومات عن المستأجر</h6>
                                                            <StructuredListWrapper ariaLabel="Structured list">
                                                                <StructuredListBody>
                                                                    <StructuredListRow tabIndex={0}>
                                                                        <StructuredListCell>
                                                                            اسم المستأجر
                                                                        </StructuredListCell>
                                                                        <StructuredListCell>
                                                                            {data[key].tenant_name}
                                                                        </StructuredListCell>
                                                                    </StructuredListRow>
                                                                    <StructuredListRow tabIndex={0}>
                                                                        <StructuredListCell>
                                                                            رقم الهوية/الإقامة

                                                                        </StructuredListCell>
                                                                        <StructuredListCell>
                                                                            {data[key].tenant_ID_Residency_number}
                                                                        </StructuredListCell>
                                                                    </StructuredListRow>
                                                                    <StructuredListRow tabIndex={0}>
                                                                        <StructuredListCell>
                                                                            الجنسية
                                                                        </StructuredListCell>
                                                                        <StructuredListCell>
                                                                            {data[key].tenant_nationality}
                                                                        </StructuredListCell>
                                                                    </StructuredListRow>
                                                                    <StructuredListRow tabIndex={0}>
                                                                        <StructuredListCell>
                                                                            جوال
                                                                        </StructuredListCell>
                                                                        <StructuredListCell>
                                                                            {data[key].tenant_phone}
                                                                        </StructuredListCell>
                                                                    </StructuredListRow>
                                                                    <StructuredListRow tabIndex={0}>
                                                                        <StructuredListCell>
                                                                            العنوان
                                                                        </StructuredListCell>
                                                                        <StructuredListCell>
                                                                            {data[key].tenant_address}
                                                                        </StructuredListCell>
                                                                    </StructuredListRow>
                                                                    <StructuredListRow tabIndex={0}>
                                                                        <StructuredListCell>
                                                                            الملف المرفق
                                                                        </StructuredListCell>
                                                                        <StructuredListCell>
                                                                            {data[key].documents ? data[key].documents.map(document => {
                                                                                return (
                                                                                    <p><a href={document.document_path} target="_blank">الملف</a></p>
                                                                                )
                                                                            }) : '-'}
                                                                        </StructuredListCell>
                                                                    </StructuredListRow>
                                                                </StructuredListBody>
                                                            </StructuredListWrapper>
                                                        </Column>
                                                        <Column>
                                                            <h6>معلومات عن الطلب</h6>

                                                            <StructuredListWrapper ariaLabel="Structured list">
                                                                <StructuredListBody>
                                                                    <StructuredListRow tabIndex={0}>
                                                                        <StructuredListCell>
                                                                            تاريخ الإستحقاق
                                                                        </StructuredListCell>
                                                                        <StructuredListCell>
                                                                            {data[key].due_date}
                                                                        </StructuredListCell>
                                                                    </StructuredListRow>
                                                                    <StructuredListRow tabIndex={0}>
                                                                        <StructuredListCell>
                                                                            الضريبة
                                                                        </StructuredListCell>
                                                                        <StructuredListCell>
                                                                            {data[key].tax_rate}
                                                                        </StructuredListCell>
                                                                    </StructuredListRow>
                                                                    <StructuredListRow tabIndex={0}>
                                                                        <StructuredListCell>
                                                                            المبلغ الكلي
                                                                        </StructuredListCell>
                                                                        <StructuredListCell>
                                                                            {data[key].amount}
                                                                        </StructuredListCell>
                                                                    </StructuredListRow>
                                                                    <StructuredListRow tabIndex={0}>
                                                                        <StructuredListCell>
                                                                            المبلغ المدفوع
                                                                        </StructuredListCell>
                                                                        <StructuredListCell>
                                                                            {data[key].paid_amount}
                                                                        </StructuredListCell>
                                                                    </StructuredListRow>
                                                                </StructuredListBody>
                                                            </StructuredListWrapper>
                                                        </Column>
                                                    </Row>
                                                </Grid>
                                            </TableExpandedRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        }

                    </TableContainer>
                )}
            </DataTable>
            {renderPagination()}
        </>
    )
}

export default withData(RentalsTable, {
    endpoint: '/orders'
})
