import React from 'react'

import { Select as CarbonSelect, SelectItem, SelectSkeleton } from 'carbon-components-react'
import withData from './withData'

function Select({
    ...props
}) {

    const { data, idKey, textKey, selectedValue, ...rest } = props
    const items = data.map(item => {
        return {
            id: item[idKey],
            text: item[textKey]
        }
    })

    return (
        <CarbonSelect {...rest}>
            <SelectItem value={0} disabled/>
            {items.map(item => (
                <SelectItem value={item.id} text={item.text}/>
            ))}
        </CarbonSelect>
    )
}

const SelectWithStores = withData(Select, {
    endpoint: '/store',
    filter: {
        per_page: -1
    }
})

export {
    SelectWithStores
}