import { Column, DatePicker, DatePickerInput, Row, Select, SelectItem, TimePicker } from 'carbon-components-react'
import moment from 'moment';
import React, { useState } from 'react'

export default function DateTimePicker({
    onChange,
    value,
    datePickerProps,
    datePickerInputProps
}) {

    const momentDate = moment(value);
    
    let date = momentDate.isValid() ? momentDate.format("YYYY-MM-DD") : "";
    let timeHour = momentDate.isValid() ? momentDate.format("hh") : "12";
    let timeMinute = momentDate.isValid() ? momentDate.format("mm") : "00";

    let time = `${timeHour}:${timeMinute}:00`

    const onTimeChange = (period, e) => {

        let lltime = '';

        if (period == 'h') {
            lltime = `${e.target.value}:${timeMinute}:00`
        } else {
            lltime = `${timeHour}:${e.target.value}:00`
        }

        if (!date) {
            date = moment().format('YYYY-MM-DD')
        }

        let newDate = moment(`${date} ${lltime}`)

        if (newDate.isValid()) {
            onChange(`${date} ${lltime}`)
        } else {
            onChange('')
        }


    }

    const onDateChange = (e) => {
        if (!Array.isArray(e) || e.length == 0) {
            onChange('')
        } else {
            let casteDate = moment(e[0].toLocaleDateString('en-US')).format("YYYY-MM-DD")

            onChange(`${casteDate} ${time}`)
        }


    }

    const getDayHours = () => {

        return [
            '01',
            '02',
            '03',
            '04',
            '05',
            '06',
            '07',
            '08',
            '09',
            '10',
            '11',
            '12',
        ]

    }

    const getDayMinutes = () => {

        let minutes = [];

        for (var i = 0; i <= 59; i++) {

            if (i < 10) {
                minutes.push(`0${i}`)
            } else {
                minutes.push(i)
            }

        }

        return minutes

    }


    const hours = getDayHours()
    const minutes = getDayMinutes()

    return (
        <Row>
            <Column>
                <DatePicker
                    onChange={onDateChange}
                    dateFormat="Y-m-d"
                    allowInput={true}
                    datePickerType="single"
                    onClick={() => onDateChange('')}
                    {...datePickerProps}
                >
                    <DatePickerInput
                        value={date}
                        {...datePickerInputProps}
                    />
                </DatePicker>
            </Column>
            <Column>
                <Row narrow>
                    <Column>
                        <Select labelText="الدقيقة" onChange={(e) => onTimeChange('m', e)}>
                            <SelectItem />
                            {minutes.map(minute => <SelectItem value={minute} label={minute} selected={minute == timeMinute} />)}
                        </Select>
                    </Column>
                    <Column>
                        <Select labelText="الساعة" onChange={(e) => onTimeChange('h', e)}>
                            <SelectItem />
                            {hours.map(hour => <SelectItem value={hour} label={hour} selected={hour == timeHour} />)}
                        </Select>
                    </Column>
                </Row>

            </Column>
        </Row>
    )

}
