// React dependecies
import React, { useState, useEffect } from 'react'

// Carbon dependecies
import {
    TextInput, TextArea, Column, Row, Form, Button, InlineLoading, InlineNotification, TextInputSkeleton
} from 'carbon-components-react'
import { spacing } from '@carbon/layout'

// External dependecies
import { useHistory } from 'react-router'

// Internal dependecies
import { fetchAPIwithAuth } from '../../helpers'
import FormSubmitButton from '../../components/form-submit-button'

// Constants

/**
 * Default form fields values
 */
const DEFAULT_STORE_FIELDS = {
    name: "",
    address: "",
}

export default function StoreForm({ id }) {

    let history = useHistory()

    // if props id passed,
    // show loading indicator to ther user until resources matches id loaded from the server 
    const [isLoading, setisLoading] = useState(id ? true : false)

    const [store, setStore] = useState(DEFAULT_STORE_FIELDS)

    /**
     * Display a notification at the top of form <InlineNotification />
     * @type {}
     */
    const [formNotification, setformNotification] = useState(false)

    /**
     * Set inputs fields errors
     */
    const [fieldErrors, setFieldsError] = useState({})

    /**
     * @param {string} field
     * @param {object} field
     * @return {null}
     */
    const handleStoreChange = (field, value) => {
        const change = { ...store }
        change[field] = value

        // Set field invalid to false when on change event fired
        if (typeof (fieldErrors[field]) !== 'undefined') {
            setFieldsError(Object.assign(fieldErrors, { [field]: false }))
        }

        setStore(change)
    }

    /**
     * 
     * @returns {Promise}
     */
    const handleSubmit = async () => {

        let errors = {};

        const { name, address } = store

        if (!name) {
            errors["name"] = true;
        }

        // Check if there is any errors
        if (Object.keys(errors).length > 0) {
            setFieldsError(errors)
            return Promise.reject({
                notification: {
                    kind: "error",
                    title: 'المرجو التحقق من المدخلات'
                }
            })
        }

        let data = {
            name: name,
            address: address,
        }

        return fetchAPIwithAuth('/store' + (id ? '/' + id : ''), {
            method: id ? "PATCH" : "POST",
            body: JSON.stringify(data)
        }).then(res => {

            const { status, body } = res

            // Resource updated successfully
            if (status == 200) {
                return Promise.resolve({
                    tool: body,
                    notification: {
                        kind: 'success',
                        title: `تم تحديث المتجر بنجاح`
                    }
                })
            }

            // Resource created successfully
            if (status == 201) {
                return Promise.resolve({
                    tool: body,
                    notification: {
                        kind: 'success',
                        title: `تم إضافة المتجر بنجاح`
                    }
                })
            }

            return Promise.reject({
                notification: {
                    kind: 'error',
                    title: 'حدث خطأ غير متوقع.',
                    subtitle: 'المرجو اعادة المحاولة مرة اخرى'
                }
            })

        })

    }

    useEffect(() => {
        // if id is passed load the tool information
        if (id) {
            fetchAPIwithAuth('/store/' + id, {
                method: "get"
            }).then(res => {
                setStore(res.body)
                setisLoading(false)
            })
        }
        return
    }, [])

    if (isLoading) {
        return (
            <Column lg={6} sm={4}>
                <TextInputSkeleton />
            </Column>
        )
    }

    return (
        <Row>

            <Column lg={6} sm={4}>

                <Form>
                    <Row style={{ marginBottom: spacing[6] }}>
                        <Column>
                            <TextInput
                                id="store-name"
                                labelText="اسم المتجر"
                                value={store.name}
                                invalid={fieldErrors?.name}
                                invalidText={"المرجو ادخال اسم المتجر"}
                                onChange={(e) => handleStoreChange("name", e.target.value)}
                            />
                        </Column>
                    </Row>

                    <Row style={{ marginBottom: spacing[6] }}>
                        <Column>
                            <TextArea
                                id="store-address"
                                labelText="عنوان المتجر"
                                value={store.address}
                                onChange={(e) => handleStoreChange("address", e.target.value)}
                                style={{ marginBottom: spacing[8] }}
                            />
                        </Column>
                    </Row>

                    {formNotification !== false && <InlineNotification
                        role='alert'
                        onClose={() => setformNotification(false)}
                        {...formNotification}
                    />
                    }

                    <FormSubmitButton>
                        {({ startSubmitting, finishSubmitting, isSubmitting, success, description, ariaLive }) => {
                            return isSubmitting || success ? (
                                <InlineLoading
                                    style={{ marginLeft: '1rem' }}
                                    description={description}
                                    status={success ? 'finished' : 'active'}
                                    aria-live={ariaLive}
                                />
                            ) : (
                                <Button onClick={() => {
                                    startSubmitting()
                                    handleSubmit().then((res) => {
                                        finishSubmitting()
                                        setformNotification(res.notification)
                                        if (!id) {
                                            setTimeout(() => {
                                                history.push('/dashboard/stores/?&id=' + res.tool.id)
                                            }, 500)
                                        }
                                    }).catch(e => {
                                        setformNotification(e.notification)
                                        finishSubmitting()
                                    })
                                }}>حفظ المتجر</Button>
                            )
                        }}
                    </FormSubmitButton>

                </Form>
            </Column>
        </Row>
    )
}
