
import React, { useState } from "react";
import ReactDOM from 'react-dom';

import {
    DataTable, TableContainer, Table, TableHead, TableRow, TableHeader, TableBody, TableCell, TableToolbar, TableToolbarContent, TableToolbarSearch, TableToolbarMenu,
    Button, Grid, DataTableSkeleton, Pagination, OverflowMenu, OverflowMenuItem, TableExpandedRow, TableExpandHeader, TableExpandRow, Row, Column, Modal, TextInput, Tag, NumberInput, Toggle, Form
} from "carbon-components-react";

import Moment from 'react-moment';

import { Add16, ErrorFilled16, CheckmarkFilled16 } from '@carbon/icons-react'
import { Link, useHistory } from "react-router-dom";
import withData from "./withData";
import { fetchAPIwithAuth, paginationProps } from "../helpers";

import moment from 'moment';
import 'moment/locale/ar-ma';
import ModalAddPayment from "../partial/ModalAddPayment";
import { useQuery } from "../hooks/useQuery";

const TABLE_HEADER = [
    {
        key: 'order_id',
        header: 'رقم الطلب',
    },
    {
        key: 'payment_amount',
        header: 'المبلغ',
    },
    {
        key: 'created_at',
        header: 'تاريخ الإنشاء',
    },
    {
        key: 'action',
        header: null,
    }
]

function PaymentsTable({ ...props }) {

    const [isModalOpened, setIsModalOpened] = useState(false)

    const history = useHistory()

    const { data, paginate, meta } = props

    const renderPagination = () => {
        return (
            <Pagination
                onChange={(e) => { paginate(e.page) }}
                {...paginationProps(meta)}
            />
        )
    }


    return (
        <>
            <DataTable rows={Array.isArray(data) ? data : []} headers={TABLE_HEADER}>
                {({ rows, headers, getHeaderProps, getTableProps, getRowProps, onInputChange }) => (

                    <TableContainer title="آخر الدفعات" description="آخر عمليات الدفع">
                        {
                            data.length == 0 && meta?.total == undefined ?  (
                                <>
                                    {<DataTableSkeleton headers={headers} showHeader={false} showToolbar={false} columnCount={headers.length} />}
                                </>
                            ) : <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, key) => (
                                        <React.Fragment>
                                            <TableRow {...getRowProps({ row })}>
                                                {row.cells.map((cell) => {
                                                    if (cell.info.header == 'action') {
                                                        return (
                                                            <TableCell key={cell.id}>
                                                                <OverflowMenu>
                                                                    <OverflowMenuItem onClick={() => window.open('/dashboard/payment-receipt?id=' + row.id)} itemText="استخراج الوصل" />
                                                                </OverflowMenu>
                                                            </TableCell>
                                                        )
                                                    }

                                                    return (
                                                        <TableCell key={cell.id}>
                                                            {['created_at'].includes(cell.info.header) ? <Moment local="ar" format="ll">{cell.value}</Moment> : cell.value}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        }

                    </TableContainer>
                )}
            </DataTable>
            {renderPagination()}
        </>
    )
}

export default withData(PaymentsTable, {
    endpoint: '/payments',
    filterBy: ['order_id']
})
