import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useAuth } from '../hooks/useUser'

export default function Logout() {

    const { signOut } = useAuth()
    const histroy = useHistory()

    useEffect(() => {

        signOut()
        histroy.push('./')

    }, [])

    return null
}
