import { API } from './const'

export const fetchAPI = async (endpoint, args) => {
    return fetch(API + endpoint, args).then(r =>  r.json().then(data => ({status: r.status, body: data})))
}

export const fetchAPIwithAuth = async (endpoint, args) => {

    const defaultArgs = {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    }

    const reqArgs = { ...defaultArgs, ...args }

    return fetchAPI(endpoint, reqArgs)

}


export const paginationProps = (meta) => {

    return {
        pageSizes: [
            meta.per_page,
        ],
        totalItems: meta.total,
        page: meta.current_page,
        pageSize: meta.per_page,
        pageSizeInputDisabled: true,
        pageNumberText: "الصفحة",
        pageText: "الصفحة",
        forwardText: "الصفحة الموالية",
        backwardText: "الصفحة السابقة",
        itemRangeText: (min, max, total) => {
            return `${min} - ${max} من ${total} عناصر`
        },
        pageRangeText: (c, t) => {
            return `صفحة ${c} من ${t}`
        },
        itemsPerPageText: "نتائج لكل صفحة"
    }

}

export const siteBaseUrl = () => {
    return 'http://localhost:8000'
}