import React, { useEffect } from 'react'

import { Row, Column } from 'carbon-components-react'
import { spacing } from '@carbon/layout'

import AppBar from "../../components/AppBar";
import { useQuery } from '../../hooks/useQuery';
import PaymentsTable from '../../components/payments-table';

function Payments() {

    let pageTitle = "الدفعات";

    return (
        <>
            <AppBar title={pageTitle} />

            <div className="pt-7"></div>

            <PaymentsTable/>

        </>
    )
}

export default Payments