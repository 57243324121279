import { useState } from 'react'

function FormSubmitButton({ children }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [description, setDescription] = useState('جاري ارسال...');
    const [ariaLive, setAriaLive] = useState('off');

    const startSubmitting = () => {
        setIsSubmitting(true);
        setAriaLive('assertive');
    };

    const finishSubmitting = () => {
        setIsSubmitting(false);
        setSuccess(false);
        //setDescription('تم ارسال!');
    }

    return children({
        startSubmitting,
        finishSubmitting,
        isSubmitting,
        success,
        description,
        ariaLive,
    });
}

export default FormSubmitButton