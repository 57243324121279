import React, { useEffect, useState } from 'react'

import { Column, Loading, Row, TextInputSkeleton } from 'carbon-components-react'

import { spacing } from '@carbon/layout'
import { useParams } from 'react-router'
import { useQuery } from '../../hooks/useQuery'
import { fetchAPIwithAuth } from '../../helpers'
import Moment from 'react-moment'
import EquipmentsOrderSummary from '../../components/EquipmentsOrderSummary'
var moment = require('moment-hijri');
moment.locale('ar-ma');// 2- Set the global locale to `ar-SA`


export default function PaymentReceipt() {

    let query = useQuery();
    const invoiceId = query.get('id')

    const [data, setdata] = useState([])

    useEffect(() => {
        if (invoiceId) {
            fetchAPIwithAuth('/payments/' + invoiceId, {
                method: "get"
            }).then(res => {
                setdata(res.body.data)
            }).then(() => {
                //window.print()
            })
        }
    }, [])

    if (data.length == 0) {
        return (
            <Loading />
        )
    }

    return (
        <div id="invoice-receipt" style={{ color: "#3b3c6c", width: 700, margin: "0 auto" }}>

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h3 style={{ color: "#f78d8d" }}>{data.id}</h3>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h1 style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>الوسـيـــط</h1>
                {/*<h4 style={{ fontWeight: 'bold' }}>جوال / ۰۵۹۸۸۸۱۸۹۰</h4>*/}
            </div>
            <div style={{ border: "2px #3b3c6c solid", padding: "1rem", marginTop: 16, borderRadius: 16, fontWeight: "bold" }}>

                <div class="bx--order-summary" style={{ width: "100%" }}>
                    <section class="bx--order-header">
                        <p class="bx--order-header-title pb-3">وصل الدفع</p>
                    </section>

                    <ul class="bx--order-list">
                        {
                            Array.isArray(data?.equipments) && data?.equipments.map(equipment => {

                                return (
                                    <li class="bx--order-category">
                                        <p class="bx--order-category-title">اسم تجريبي</p>
                                        <ul>
                                            <li class="bx--order-item">
                                                <p class="bx--order-detail">الكمية</p>
                                                <p class="bx--order-price">{equipment.quantity}</p>
                                            </li>
                                            {equipment.return_date && (<li class="bx--order-item">
                                                <p class="bx--order-detail">تاريخ الإرجاع</p>
                                                <p class="bx--order-price"><Moment format='YY/MM/DD'>{equipment.return_date}</Moment></p>
                                            </li>)
                                            }
                                        </ul>
                                    </li>
                                )
                            })
                        }


                    </ul>

                    <section class="bx--order-total-container">
                        <div class="bx--order-total">
                            <p class="bx--order-total-text" style={{ fontWeight: '400' }}>اسم المدين</p>
                            <p class="bx--order-total-price" style={{ fontWeight: '400' }}>
                                {data.order.tenant_name}
                            </p>
                        </div>
                        <div class="bx--order-total">
                            <p class="bx--order-total-text" style={{ fontWeight: '400' }}>رقم الهوية/الإقامة</p>
                            <p class="bx--order-total-price" style={{ fontWeight: '400' }}>
                                {data.order.tenant_ID_Residency_number}
                            </p>
                        </div>
                        <div class="bx--order-total">
                            <p class="bx--order-total-text" style={{ fontWeight: '400' }}>رقم الطلب</p>
                            <p class="bx--order-total-price">
                                {`#${data?.order_id}`}
                            </p>
                        </div>
                        <div class="bx--order-total">
                            <p class="bx--order-total-text">تاريخ الإنشاء</p>
                            <p class="bx--order-total-price">
                                {data.created_at ? <Moment format='YY/MM/DD'>{data.created_at}</Moment> : '-'}
                            </p>
                        </div>
                        <div class="bx--order-total">
                            <p class="bx--order-total-text">المبلغ المدفوع</p>
                            <p class="bx--order-total-price">
                                {data.payment_amount ? data.payment_amount : "0.00"} ر.س
                            </p>
                        </div>
                    </section>
                </div>

            </div>
        </div>
    )
}
