// React dependecies
import React, { useEffect, useState } from 'react'

// Carbon dependences
import {
    TextInput, NumberInput, Column, Row, Form, Button, TextInputSkeleton, InlineLoading, InlineNotification
} from 'carbon-components-react'
import { spacing } from '@carbon/layout'

// External dependecies
import { useHistory } from 'react-router'

// Internal dependecies 
import { fetchAPIwithAuth } from '../../helpers'
import { SelectWithStores } from '../../components/select'
import FileUploader from '../../components/file-uploader'
import FormSubmitButton from '../../components/form-submit-button'

// Constants

/**
 * Default form fields values
 */
const DEFAULT_TOOL_FIELDS = {
    name: "",
    store: 0,
    rent_price: 1,
    quantity: 1,
    media_id: 0,
    media: []
}

export default function ToolForm({ id }) {

    let history = useHistory()

    // if props id passed,
    // show loading indicator to ther user until resources matches id loaded from the server 
    const [isLoading, setisLoading] = useState(id ? true : false)

    const [tool, setTool] = useState(DEFAULT_TOOL_FIELDS)

    /**
     * Display a notification at the top of form <InlineNotification />
     * @type {}
     */
    const [formNotification, setformNotification] = useState(false)

    /**
     * Set inputs fields errors
     */
    const [fieldErrors, setFieldsError] = useState({})

    /**
     * @param {string} field
     * @param {object} field
     * @return {null}
     */
    const handleToolChange = (field, value) => {
        const change = { ...tool }
        change[field] = value

        // Set field invalid to false when on change event fired
        if (typeof (fieldErrors[field]) !== 'undefined') {
            setFieldsError(Object.assign(fieldErrors, { [field]: false }))
        }

        setTool(change)
    }

    /**
     * 
     * @returns {Promise}
     */
    const handleSubmit = async () => {

        let errors = {};

        const { name, store, rent_price, quantity, media_id } = tool

        if (!name) {
            errors["name"] = true;
        }

        if (!store || store == 0) {
            errors['store'] = true
        }

        // Check if there is any errors
        if (Object.keys(errors).length > 0) {
            setFieldsError(errors)
            return Promise.reject({
                notification: {
                    kind: "error",
                    title: 'المرجو التحقق من المدخلات'
                }
            })
        }

        let data = {
            name: name,
            store_id: store,
            rent_price: rent_price,
            quantity: quantity,
        }

        // Include media
        if (media_id) {
            data['media_id'] = media_id
        }

        return fetchAPIwithAuth('/equipments' + (id ? '/' + id : ''), {
            method: id ? "PATCH" : "POST",
            body: JSON.stringify(data)
        }).then(res => {

            const { status, body } = res

            // Resource updated successfully
            if (status == 200) {
                return Promise.resolve({
                    tool: body,
                    notification: {
                        kind: 'success',
                        title: `تم تحديث المعدة بنجاح`
                    }
                })
            }

            // Resource created successfully
            if (status == 201) {
                return Promise.resolve({
                    tool: body,
                    notification: {
                        kind: 'success',
                        title: `تم إضافة المعدة بنجاح`
                    }
                })
            }

            return Promise.reject({
                notification: {
                    kind: 'error',
                    title: 'حدث خطأ غير متوقع.',
                    subtitle: 'المرجو اعادة المحاولة مرة اخرى'
                }
            })

        })

    }

    useEffect(() => {
        // if id is passed load the tool information
        if (id) {
            fetchAPIwithAuth('/equipments/' + id, {
                method: "get"
            }).then(res => {
                setTool(res.body)
                setisLoading(false)
            })
        }
        return
    }, [])

    if (isLoading) {
        return (
            <Column lg={6} sm={4}>
                <TextInputSkeleton />
            </Column>
        )
    }

    return (
        <Row>

            <Column lg={6} sm={4}>


                <Form>
                    <Row style={{ marginBottom: spacing[6] }}>
                        <Column>
                            <TextInput
                                id="tool-name"
                                labelText="اسم المعدة"
                                name={"name"}
                                value={tool.name}
                                invalidText={"المرجو ادخال اسم المعدة"}
                                invalid={fieldErrors?.name}
                                onChange={(e) => handleToolChange("name", e.target.value)}
                            />
                        </Column>
                    </Row>
                    <Row style={{ marginBottom: spacing[6] }}>
                        <Column>
                            <SelectWithStores
                                onChange={(e) => handleToolChange("store", e.target.value)}
                                id="tool-store"
                                defaultValue={0}
                                selectedValue={tool.store}
                                name="store"
                                invalidText="المرجو اختيار متجر المعدة"
                                invalid={fieldErrors?.store}
                                labelText="متجر المعدة"
                                idKey='id'
                                textKey='name'
                            />

                        </Column>

                        <Column>
                            <NumberInput
                                id="tool-rent-price"
                                value={tool.rent_price}
                                name="rent_price"
                                onChange={(e) => handleToolChange('rent_price', e.imaginaryTarget.value)}
                                min={1}
                                invalid={fieldErrors?.rent_price}
                                label="ثمن التأجير" />
                        </Column>
                    </Row>
                    <Row style={{ marginBottom: spacing[6] }}>
                        <Column>
                            <NumberInput
                                id="tool-quantity"
                                value={tool.quantity}
                                onChange={(e) => handleToolChange('quantity', e.imaginaryTarget.value)}
                                min={1}
                                step={1}
                                name="quantity"
                                invalid={fieldErrors?.quantity}
                                label="الكمية" />
                        </Column>
                    </Row>

                    <div className="bx--file__container" style={{ marginBottom: spacing[8] }}>

                        <FileUploader
                            isEdit={id !== null}
                            currentFiles={[tool.media]}
                            onUploadSucceeded={(media) => handleToolChange('media_id', media[0].id)}
                        />

                    </div>

                    {formNotification !== false && <InlineNotification
                        role='alert'
                        onClose={() => setformNotification(false)}
                        {...formNotification}
                    />
                    }

                    <FormSubmitButton>
                        {({ startSubmitting, finishSubmitting, isSubmitting, success, description, ariaLive }) => {
                            return isSubmitting || success ? (
                                <InlineLoading
                                    style={{ marginLeft: '1rem' }}
                                    description={description}
                                    status={success ? 'finished' : 'active'}
                                    aria-live={ariaLive}
                                />
                            ) : (
                                <Button onClick={() => {
                                    startSubmitting()
                                    handleSubmit().then((res) => {
                                        finishSubmitting()
                                        setformNotification(res.notification)
                                        if (!id) {
                                            setTimeout(() => {
                                                history.push('/dashboard/tools/?&id=' + res.tool.id)
                                            }, 500)
                                        }
                                    }).catch(e => {
                                        setformNotification(e.notification)
                                        finishSubmitting()
                                    })
                                }}>حفظ المعدة</Button>
                            )
                        }}
                    </FormSubmitButton>

                </Form>
            </Column>
        </Row>
    )
}
