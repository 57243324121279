import React from 'react'
import AppBar from '../../components/AppBar';
import EmployeesTable from '../../components/EmployeesTable';
import { useQuery } from '../../hooks/useQuery';
import EmployeeForm from '../../partial/forms/employee';

function Employees() {

    let query = useQuery();

    let action = query.get('action')
    const employeeId = query.get('id')

    if (employeeId) {
        action = 'edit';
    }

    let pageTitle = "الموظفين";

    if (action == 'add') {
        pageTitle = 'اضافة موظف'
    } else if (action == 'edit') {
        pageTitle = 'تعديل'
    }

    return (
        <>
            <AppBar title={pageTitle} />

            <div className="pt-7"></div>

            {!employeeId && !action && <EmployeesTable />}

            {
                (action == 'edit' || action == 'add') && (
                    <EmployeeForm id={employeeId} />
                )
            }
        </>
    )
}

export default Employees
