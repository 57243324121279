import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Modal, NumberInput, InlineNotification } from 'carbon-components-react'
import { fetchAPIwithAuth } from '../helpers';

export default function ModalAddPayment({
    orderId,
    isModalOpen,
    closeModal
}) {


    const [isSaving, setIsSaving] = useState(false);
    const [formNotification, setformNotification] = useState(false)

    const [form, setForm] = useState({
        order_id: orderId,
        amount: 1
    });

    const handleFormChange = (key, field, value) => {
        const change = { ...form }

        if (!value) {
            change[key] = field
        } else {
            change[key][field] = value
        }

        setForm(change)
    }

    const save = async () => {

        let data = {
            order_id: orderId,
            amount: form.amount
        }
        console.log(form)

        return fetchAPIwithAuth('/payments', {
            method: "POST",
            body: JSON.stringify(data)
        }).then(res => {

            setIsSaving(false)

            const { status, body } = res
            if (status == 200) {
                return Promise.resolve({
                    notification: {
                        kind: 'success',
                        title: `تم اضافة الدفعة بنجاح`
                    }
                })
            }
            return Promise.reject({
                notification: {
                    kind: 'error',
                    title: 'حدث خطأ غير متوقع.',
                    subtitle: 'المرجو اعادة المحاولة مرة اخرى'
                }
            })

        })


    }

    const initialModal = () => {
        setIsSaving(false)
        setformNotification(false)
        setForm({
            amount: 1,
            order_id: null
        })
    }

    return (
        ReactDOM.createPortal(
            <Modal
                open={isModalOpen}
                hasForm
                size="sm"
                modalHeading="اضف دفعة"
                modalLabel="الدفعات"
                primaryButtonDisabled={isSaving}
                primaryButtonText={isSaving ? "جاري الحفظ ..." : "اضف"}
                secondaryButtonText="الغاء"
                preventCloseOnClickOutside
                onRequestSubmit={() => {
                    setIsSaving(true)
                    save().then((res) => {
                        setformNotification(res.notification)
                        location.reload()
                    }).catch((error) => {
                        setformNotification(error.notification)
                    })
                }}
                onRequestClose={() => {
                    initialModal()
                    closeModal()
                }}
            >
                <NumberInput
                    id="carbon-number"
                    onChange={(e) => {
                        handleFormChange('amount', e.imaginaryTarget.value)
                    }}
                    //max={}
                    value={form.amount}
                    label="المبلغ"
                    invalidText="Number is not valid"
                    min={1}
                />

                {formNotification !== false && <InlineNotification
                    role='alert'
                    onClose={() => setformNotification(false)}
                    {...formNotification}
                />}

            </Modal>, document.body
        )
    )
}
