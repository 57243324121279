import React, { useState } from 'react'

import { Form, TextInput, Button, InlineLoading } from 'carbon-components-react'
import { useAuth } from '../../hooks/useUser'
import FormSubmitButton from '../../components/form-submit-button'
import {
    Add16
} from "@carbon/icons-react"
import { useHistory } from 'react-router'

export default function LoginForm() {

    const history = useHistory()

    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")

    const { signIn } = useAuth();

    const handleSubmit = async() => {

        return signIn(email, password).then(r => {
            history.push('/')
        }).catch(error => {
            return Promise.reject(error)
        })
    }

    return (
        <Form>
            <div className="mb-5">
                <TextInput
                    id="email"
                    value={email}
                    type="email"
                    onChange={e => setemail(e.target.value)}
                    labelText="البريد الإلكتروني"
                />
            </div>
            <div className="mb-6">
                <TextInput
                    id="password"
                    value={password}
                    type="password"
                    onChange={e => setpassword(e.target.value)}
                    labelText="كلمة المرور"
                />
            </div>
            <FormSubmitButton>
                {({ startSubmitting, finishSubmitting, isSubmitting, success, description, ariaLive }) => {
                    return isSubmitting || success ? (
                        <InlineLoading
                            style={{ marginLeft: '1rem' }}
                            description={description}
                            status={success ? 'finished' : 'active'}
                            aria-live={ariaLive}
                        />
                    ) : (
                        <Button renderIcon={Add16} onClick={() => {
                            startSubmitting()
                            handleSubmit().then((res) => {
                                //finishSubmitting()
                            }).catch(e => {
                                finishSubmitting()
                            })
                        }}>
                        تسجيل الدخول
                        </Button>
                    )
                }}
            </FormSubmitButton>

        </Form>
    )
}
