import React, { useState } from 'react'
import { Button, Column, Form, MultiSelect, NumberInput, Row, TextArea, TextInput, DatePicker, DatePickerInput, TimePicker, TimePickerSelect, Grid, Select, SelectItem } from 'carbon-components-react'
import AppBar from '../../components/AppBar'
import DashLayout from '../../components/DashLayout'
import {
    Add16
} from "@carbon/icons-react";

import { spacing } from '@carbon/layout'

console.log(spacing)

export default function AddInvoice() {

    const [invoiceToolsCount, setinvoiceToolsCount] = useState(1)

    return (

        <>
            <AppBar title="أضف فاتورة" />

            <div className="pt-7"></div>

            <Row>

                <Column lg={6} sm={4}>

                    <Form>

                        <h5 style={{ marginBottom: spacing[7] }}>معلومات المستأجر</h5>


                        <Row style={{ marginBottom: "2rem" }}>
                            <Column>
                                <TextInput
                                    labelText="اسم المستأجر"
                                />
                            </Column>
                        </Row>
                        <Row style={{ marginBottom: "2rem" }}>
                            <Column>
                                <TextInput
                                    labelText="اسم المستأجر"
                                />
                            </Column>
                            <Column>
                                <TextInput
                                    labelText="اسم المستأجر"
                                />
                            </Column>
                        </Row>
                        <Row style={{ marginBottom: spacing[7] }}>
                            <Column>
                                <TextArea
                                    labelText="اسم المستأجر"
                                />
                            </Column>
                        </Row>


                        <h5 style={{ marginBottom: spacing[7] }}>المعدات</h5>


                        <Row style={{ marginBottom: "2rem" }}>
                            <Column>
                                <Select
                                    id="test"
                                    labelText="المتجر"
                                >
                                    <SelectItem text="متجر" />
                                </Select>

                            </Column>
                        </Row>

                        <Row style={{ marginBottom: "2rem" }}>
                            <Column>
                                <MultiSelect
                                    id="test"
                                    label="المعدات"
                                    titleText="المعدات"
                                    items={[
                                        {
                                            id: 'downshift-1-item-0',
                                            text: 'Option 1',
                                        },
                                        {
                                            id: 'downshift-1-item-1',
                                            text: 'Option 2',
                                        },
                                    ]}
                                    itemToString={(item) => (item ? item.text : '')}
                                />
                            </Column>
                        </Row>

                        <Row style={{ marginBottom: "2rem" }}>
                            <Column>
                                <DatePicker datePickerType="single" locale="ar">
                                    <DatePickerInput
                                        placeholder="mm/dd/yyyy"
                                        labelText="تاريخ الإرجاع"
                                        id="date-picker-single"
                                    />

                                </DatePicker>
                            </Column>
                            <Column>
                                <div style={{ display: "flex", alignItems: "flex-end" }}>
                                    <TimePicker step="2" labelText="الوقت" />
                                    <TimePickerSelect style={{ marginRight: "0.125rem" }} />
                                </div>

                            </Column>
                        </Row>

                        <h6 style={{ marginBottom: spacing[5] }}>خلاط كهربائي</h6>

                        <Row style={{ marginBottom: spacing[8] }}>
                            <Column>
                                <NumberInput
                                    label="الإيجار اليومي"
                                />
                            </Column>
                            <Column>
                                <NumberInput
                                    label="المدفوع"
                                />

                            </Column>
                        </Row>


                        <Button renderIcon={Add16}>
                            حفظ الفاتورة
                        </Button>


                    </Form>


                </Column>
            </Row>
        </>

    )
}