import { Column, Grid, Row, StructuredListBody, StructuredListCell, StructuredListRow, StructuredListWrapper, TableContainer, Table, TableHead, TableRow, TableHeader, TableBody, DataTable, Button, TableCell, OverflowMenu, OverflowMenuItem, ToastNotification, InlineNotification } from 'carbon-components-react';
import React, { useEffect, useState } from 'react'
import AppBar from '../../components/AppBar';

import {
    Add16,
    Printer16
} from "@carbon/icons-react"

import { useQuery } from '../../hooks/useQuery'
import { fetchAPIwithAuth } from '../../helpers';

const TABLE_HEADER = [
    {
        key: 'id',
        header: '#'
    },
    {
        key: 'name',
        header: 'المعدة',
    },
    {
        key: 'quantity',
        header: 'الكمية',
    },
    {
        key: 'start_date',
        header: 'تاريخ النفاذ',
    },
    {
        key: 'return_date',
        header: 'تاريخ الإرجاع',
    },
    {
        key: 'status',
        header: 'الحالة',
    },
    {
        key: 'action',
        header: null
    }
]

function RentDetails() {

    let query = useQuery();

    const [rent, setRent] = useState([])

    const id = query.get('id')

    if (!id) {
        return;
    }

    useEffect(() => {

        fetchAPIwithAuth('/orders/' + id, {
            method: "get",
        }).then(res => {
            setRent(res.body)
        })

        return;

    }, [])

    const updateRentalStatus = (rentId, newStatus) => {
        fetchAPIwithAuth('/rental/' + rentId, {
            method: "put",
            body: JSON.stringify({
                status: newStatus
            })
        }).then(res => {

        })
    }

    return (
        <>
            <AppBar title={"تفاصيل التأجير"} />

            <div className="pt-7"></div>


            <Grid>
                <Row>
                    <Column>
                        <h5>معلومات عن المستأجر</h5>
                        <StructuredListWrapper ariaLabel="Structured list">
                            <StructuredListBody>
                                <StructuredListRow tabIndex={0}>
                                    <StructuredListCell>
                                        اسم المستأجر
                                    </StructuredListCell>
                                    <StructuredListCell>
                                        {rent.tenant_name}
                                    </StructuredListCell>
                                </StructuredListRow>
                                <StructuredListRow tabIndex={0}>
                                    <StructuredListCell>
                                        رقم الهوية/الإقامة

                                    </StructuredListCell>
                                    <StructuredListCell>
                                        {rent.tenant_ID_Residency_number}
                                    </StructuredListCell>
                                </StructuredListRow>
                                <StructuredListRow tabIndex={0}>
                                    <StructuredListCell>
                                        الجنسية
                                    </StructuredListCell>
                                    <StructuredListCell>
                                        {rent.tenant_nationality}
                                    </StructuredListCell>
                                </StructuredListRow>
                                <StructuredListRow tabIndex={0}>
                                    <StructuredListCell>
                                        جوال
                                    </StructuredListCell>
                                    <StructuredListCell>
                                        {rent.tenant_phone}
                                    </StructuredListCell>
                                </StructuredListRow>
                                <StructuredListRow tabIndex={0}>
                                    <StructuredListCell>
                                        العنوان
                                    </StructuredListCell>
                                    <StructuredListCell>
                                        {rent.tenant_address}
                                    </StructuredListCell>
                                </StructuredListRow>
                            </StructuredListBody>
                        </StructuredListWrapper>

                    </Column>
                    <Column>
                        <h5>معلومات عن الطلب</h5>

                        <StructuredListWrapper ariaLabel="Structured list">
                            <StructuredListBody>
                                <StructuredListRow tabIndex={0}>
                                    <StructuredListCell>
                                        تاريخ الإستحقاق
                                    </StructuredListCell>
                                    <StructuredListCell>
                                        {rent.due_date}
                                    </StructuredListCell>
                                </StructuredListRow>
                                <StructuredListRow tabIndex={0}>
                                    <StructuredListCell>
                                        الضريبة
                                    </StructuredListCell>
                                    <StructuredListCell>
                                        {rent.tax_rate}
                                    </StructuredListCell>
                                </StructuredListRow>
                                <StructuredListRow tabIndex={0}>
                                    <StructuredListCell>
                                        المبلغ الكلي
                                    </StructuredListCell>
                                    <StructuredListCell>
                                        {rent.amount}
                                    </StructuredListCell>
                                </StructuredListRow>
                                <StructuredListRow tabIndex={0}>
                                    <StructuredListCell>
                                        المبلغ المدفوع
                                    </StructuredListCell>
                                    <StructuredListCell>
                                        {rent.paid_amount}
                                    </StructuredListCell>
                                </StructuredListRow>
                                <StructuredListRow tabIndex={0}>
                                    <StructuredListCell>
                                        الحالة
                                    </StructuredListCell>
                                    <StructuredListCell>
                                        {rent.status}
                                    </StructuredListCell>
                                </StructuredListRow>
                            </StructuredListBody>
                        </StructuredListWrapper>
                    </Column>
                    <Column>
                        <div className='d-flex flex-column'>
                            <Button kind='primary' renderIcon={Printer16}>طباعة الوصل</Button>
                            <Button kind='secondary' renderIcon={Printer16}>طباعة الفاتورة</Button>
                        </div>
                        <StructuredListWrapper ariaLabel="Structured list">
                            <StructuredListBody>
                                <StructuredListRow tabIndex={0}>
                                    <StructuredListCell>
                                        رقم
                                    </StructuredListCell>
                                    <StructuredListCell>
                                        {rent.ref}
                                    </StructuredListCell>
                                </StructuredListRow>
                                <StructuredListRow tabIndex={0}>
                                    <StructuredListCell>
                                        تاريخ الإنشاء
                                    </StructuredListCell>
                                    <StructuredListCell>
                                        {rent.created_at}
                                    </StructuredListCell>
                                </StructuredListRow>
                                <StructuredListRow tabIndex={0}>
                                    <StructuredListCell>
                                        الموظف
                                    </StructuredListCell>
                                    <StructuredListCell>
                                        Row 2
                                    </StructuredListCell>
                                </StructuredListRow>
                            </StructuredListBody>
                        </StructuredListWrapper>
                    </Column>
                </Row>
            </Grid>


            <InlineNotification
                kind="error"
                iconDescription="describes the close button"
                subtitle="مستحقات هذا الطلب غير مدفوعة"
                title="الدفع غير كامل"
                hideCloseButton={true}
                
            />


            <DataTable rows={rent?.equipments ? rent?.equipments : []} headers={TABLE_HEADER}>
                {({
                    rows,
                    headers,
                    getHeaderProps,
                    getRowProps,
                    getTableProps,
                    getTableContainerProps,
                }) => (
                    <TableContainer
                        title="المعدات"
                        //description="Usage example"
                        {...getTableContainerProps()}>
                        <Table {...getTableProps()} isSortable>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) => (
                                        <TableHeader
                                            id={header.key}
                                            key={header.key}
                                            {...getHeaderProps({ header })}
                                            isSortable>
                                            {header.header}
                                        </TableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id} {...getRowProps({ row })}>
                                        {row.cells.map((cell) => {

                                            if (cell.info.header == 'action' && row.cells[5].value != 'returned') {

                                                return (
                                                    <TableCell key={cell.id}>
                                                        <OverflowMenu>
                                                            <OverflowMenuItem onClick={() => updateRentalStatus(1, 'returned')} itemText="ارجاع" />
                                                        </OverflowMenu>
                                                    </TableCell>
                                                )
                                            }

                                            return (
                                                <TableCell key={cell.id}>{cell.value}</TableCell>
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DataTable>

        </>
    )


}

export default RentDetails