// React dependecies
import React, { useEffect, useState } from 'react'

// Carbon dependences
import {
    TextInput, NumberInput, Column, Row, Form, Button, TextInputSkeleton, InlineLoading, InlineNotification
} from 'carbon-components-react'
import { spacing } from '@carbon/layout'

// External dependecies
import { useHistory } from 'react-router'

// Internal dependecies 
import { fetchAPIwithAuth } from '../../helpers'
import { SelectWithStores } from '../../components/select'
import FileUploader from '../../components/file-uploader'
import FormSubmitButton from '../../components/form-submit-button'

// Constants

/**
 * Default form fields values
 */
const DEFAULT_EMPLOYEE_FIELDS = {
    name: "",
    store: 0,
    email: "",
    password: ""
}

export default function EmployeeForm({ id }) {

    let history = useHistory()

    // if props id passed,
    // show loading indicator to ther user until resources matches id loaded from the server 
    const [isLoading, setisLoading] = useState(id ? true : false)

    const [employee, setEmployee] = useState(DEFAULT_EMPLOYEE_FIELDS)

    /**
     * Display a notification at the top of form <InlineNotification />
     * @type {}
     */
    const [formNotification, setformNotification] = useState(false)

    /**
     * Set inputs fields errors
     */
    const [fieldErrors, setFieldsError] = useState({})

    /**
     * @param {string} field
     * @param {string} field
     * @return {null}
     */
    const handleEmployeeChange = (field, value) => {
        const change = { ...employee }
        change[field] = value

        // Set field invalid to false when on change event fired
        if (typeof (fieldErrors[field]) !== 'undefined') {
            setFieldsError(Object.assign(fieldErrors, { [field]: false }))
        }

        setEmployee(change)
    }

    /**
     * 
     * @returns {Promise}
     */
    const handleSubmit = async () => {

        let errors = {};

        const { name, email, store, password } = employee

        if (!name) {
            errors["name"] = true;
        }

        if (!email) {
            errors['store'] = true
        }

        // Check if there is any errors
        if (Object.keys(errors).length > 0) {
            setFieldsError(errors)
            return Promise.reject({
                notification: {
                    kind: "error",
                    title: 'المرجو التحقق من المدخلات'
                }
            })
        }

        let data = {
            name: name,
            store: store,
            email: email,
            password: password
        }

        return fetchAPIwithAuth('/user' + (id ? '/' + id : ''), {
            method: id ? "PATCH" : "POST",
            body: JSON.stringify(data)
        }).then(res => {

            const { status, body } = res

            // Resource updated successfully
            if (status == 200) {
                return Promise.resolve({
                    employee: body,
                    notification: {
                        kind: 'success',
                        title: `تم تحديث الموظف بنجاح`
                    }
                })
            }

            // Resource created successfully
            if (status == 201) {
                return Promise.resolve({
                    employee: body,
                    notification: {
                        kind: 'success',
                        title: `تم إضافة الموظف بنجاح`
                    }
                })
            }

            return Promise.reject({
                notification: {
                    kind: 'error',
                    title: 'حدث خطأ غير متوقع.',
                    subtitle: 'المرجو اعادة المحاولة مرة اخرى'
                }
            })

        })

    }

    useEffect(() => {
        // if id is passed load the tool information
        if (id) {
            fetchAPIwithAuth('/user/' + id, {
                method: "get"
            }).then(res => {
                setEmployee(res.body)
                setisLoading(false)
            })
        }
        return
    }, [])

    if (isLoading) {
        return (
            <Column lg={6} sm={4}>
                <TextInputSkeleton />
            </Column>
        )
    }

    return (
        <Row>

            <Column lg={6} sm={4}>


                <Form>
                    <Row style={{ marginBottom: spacing[6] }}>
                        <Column>
                            <TextInput
                                id="employee-name"
                                labelText="اسم الموظف"
                                name={"name"}
                                value={employee.name}
                                invalidText={"المرجو ادخال اسم الموظف"}
                                invalid={fieldErrors?.name}
                                onChange={(e) => handleEmployeeChange("name", e.target.value)}
                            />
                        </Column>
                    </Row>
                    <Row style={{ marginBottom: spacing[6] }}>
                        <Column>
                            <SelectWithStores
                                onChange={(e) => handleEmployeeChange("store", e.target.value)}
                                id="employee-store"
                                defaultValue={0}
                                value={employee.store_id}
                                name="store"
                                invalidText="المرجو اختيار متجر الموظف"
                                invalid={fieldErrors?.store}
                                labelText="متجر الموظف"
                                idKey='id'
                                textKey='name'
                            />

                        </Column>
                    </Row>

                    <Row style={{ marginBottom: spacing[6] }}>
                        <Column>
                            <TextInput
                                id="employee-email"
                                labelText="البريد الالكتروني"
                                name={"email"}
                                type="email"
                                value={employee.email}
                                invalidText={"المرجو ادخال البريد الالكتروني"}
                                invalid={fieldErrors?.email}
                                onChange={(e) => handleEmployeeChange("email", e.target.value)}
                            />
                        </Column>
                    </Row>

                    <Row style={{ marginBottom: spacing[6] }}>
                        <Column>
                            <TextInput
                                id="employee-password"
                                labelText="كلمة المرور"
                                name={"password"}
                                type={"password"}
                                value={employee.password}
                                invalidText={"المرجو ادخال كلمة المرور "}
                                invalid={fieldErrors?.password}
                                onChange={(e) => handleEmployeeChange("password", e.target.value)}
                            />
                        </Column>
                    </Row>

                    {formNotification !== false && <InlineNotification
                        role='alert'
                        onClose={() => setformNotification(false)}
                        {...formNotification}
                    />
                    }

                    <FormSubmitButton>
                        {({ startSubmitting, finishSubmitting, isSubmitting, success, description, ariaLive }) => {
                            return isSubmitting || success ? (
                                <InlineLoading
                                    style={{ marginLeft: '1rem' }}
                                    description={description}
                                    status={success ? 'finished' : 'active'}
                                    aria-live={ariaLive}
                                />
                            ) : (
                                <Button onClick={() => {
                                    startSubmitting()
                                    handleSubmit().then((res) => {
                                        finishSubmitting()
                                        setformNotification(res.notification)
                                        if (!id) {
                                            setTimeout(() => {
                                                history.push('/dashboard/employees')
                                            }, 500)
                                        }
                                    }).catch(e => {
                                        setformNotification(e.notification)
                                        finishSubmitting()
                                    })
                                }}>حفظ الموظف</Button>
                            )
                        }}
                    </FormSubmitButton>

                </Form>
            </Column>
        </Row>
    )
}
