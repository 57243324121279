
import React, { useState } from "react";
import ReactDOM from 'react-dom';

import {
    DataTable, TableContainer, Table, TableHead, TableRow, TableHeader, TableBody, TableCell, TableToolbar, TableToolbarContent, TableToolbarSearch, TableToolbarMenu,
    Button, Grid, DataTableSkeleton, Pagination, OverflowMenu, OverflowMenuItem, TableExpandedRow, TableExpandHeader, TableExpandRow, Row, Column, Modal, TextInput, Tag, NumberInput, Toggle, Form
} from "carbon-components-react";

import Moment from 'react-moment';

import { Add16, ErrorFilled16, CheckmarkFilled16 } from '@carbon/icons-react'
import { Link, useHistory } from "react-router-dom";
import withData from "./withData";
import { fetchAPIwithAuth, paginationProps } from "../helpers";

import moment from 'moment';
import 'moment/locale/ar-ma';
import EditOrderModal from "../partial/EditOrderModal";
import ModalAddMaintenance from "../partial/ModalAddMaintenance";

const TABLE_HEADER = [
    {
        key: 'equipment_name',
        header: 'المعدة',
    },
    {
        key: 'quantity',
        header: 'الكمية',
    },
    {
        key: 'created_at',
        header: 'تاريخ الإرسال',
    },
    {
        key: 'employee_name',
        header: 'الموظف',
    },
    {
        key: 'status',
        header: 'الحالة',
    },
    {
        key: 'action',
        header: null,
    }
]


function ReturnToStockButton({id}) {

    const [isSubmitting, setIsSubmitting] = useState(false)
    console.log(id)
    return (
        <OverflowMenuItem
            itemText={isSubmitting ? "جاري التحديث ..." : "ارجاع للمخزون"}
            onClick={() => {
                setIsSubmitting(true)

                fetchAPIwithAuth('/maintenance/' + id, {
                    method: "PATCH",
                    body: JSON.stringify({
                        action: "update-status",
                        status: "returned"
                    })
                }).then(res => {
                    const { status, body } = res

                    // Resource updated successfully
                    if (status == 200) {
                        location.reload()
                        return;
                    }
        
                    setIsSubmitting(false)
                    alert('خدث خطأ المرجو اعادة المحاولة')
        
                })

            }}
        />

    )


}

function MaintenanceTable({ ...props }) {

    const [isEditModalOpened, setIsEditModalOpened] = useState(false)

    const history = useHistory()

    const { data, paginate, meta } = props

    const renderPagination = () => {
        return (
            <Pagination
                onChange={(e) => { paginate(e.page) }}
                {...paginationProps(meta)}
            />
        )
    }


    return (
        <>
            {<ModalAddMaintenance isModalOpen={isEditModalOpened} closeModal={() => setIsEditModalOpened(false)} />}
            <DataTable rows={Array.isArray(data) ? data : []} headers={TABLE_HEADER}>
                {({ rows, headers, getHeaderProps, getTableProps, getRowProps, onInputChange }) => (

                    <TableContainer title="آخر أعمال الصيانة" description="آخر عمليات الصيانة">
                        <TableToolbar>
                            <TableToolbarContent>
                                <TableToolbarSearch expanded placeholder="بحث ..." onChange={onInputChange} />
                                <Button renderIcon={Add16} onClick={() => setIsEditModalOpened(true)}>
                                    ارسال للصيانة
                                </Button>
                            </TableToolbarContent>
                        </TableToolbar>

                        {
                            data.length == 0 ? (
                                <>
                                    {<DataTableSkeleton headers={headers} showHeader={false} showToolbar={false} columnCount={headers.length} />}
                                </>
                            ) : <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, key) => (
                                        <React.Fragment>
                                            <TableRow {...getRowProps({ row })}>
                                                {row.cells.map((cell) => {
                                                    if (cell.info.header == 'action') {
                                                        return (
                                                            <TableCell key={cell.id}>
                                                                <OverflowMenu>
                                                                    <ReturnToStockButton id={data[key].id}/>
                                                                </OverflowMenu>
                                                            </TableCell>
                                                        )
                                                    }

                                                    if (cell.info.header == "status") {
                                                        return (
                                                            <TableCell key={cell.id}>
                                                                <Tag type={'blue'}>
                                                                    {cell.value == 'dispatched' ? 'في الصيانة' : 'في المخزن'}
                                                                </Tag>
                                                            </TableCell>
                                                        )
                                                    }

                                                    return (
                                                        <TableCell key={cell.id}>
                                                            {['created_at'].includes(cell.info.header) ? <Moment local="ar" format="ll">{cell.value}</Moment> : cell.value}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        }

                    </TableContainer>
                )}
            </DataTable>
            {renderPagination()}
        </>
    )
}

export default withData(MaintenanceTable, {
    endpoint: '/maintenance'
})
