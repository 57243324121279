import React, { useEffect } from 'react'

import { Row, Column } from 'carbon-components-react'
import { spacing } from '@carbon/layout'

import AppBar from "../../components/AppBar";
import CardStats from "../../components/CardStats";
import DashLayout from "../../components/DashLayout";
import RentalsTable from "../../components/RentalsTable";
import { useQuery } from '../../hooks/useQuery';
import InvoiceForm from '../../partial/forms/invoice';
import MaintenanceTable from '../../components/maintenance-table';

function Maintenance() {

    const query = useQuery()

    let action = query.get('action')
    const invoiceId = query.get('id')

    if (invoiceId) {
        action = 'edit';
    }

    let pageTitle = "أعمال الصيانة";

    return (
        <>
            <AppBar title={pageTitle} />

            <div className="pt-7"></div>

            <MaintenanceTable/>

        </>
    )
}

export default Maintenance