import React, { useState, useEffect } from 'react'

import { FileUploader as CarbonFileUploader, FileUploaderItem } from 'carbon-components-react'
import { fetchAPIwithAuth } from '../helpers';

export default function FileUploader({
    ...props
}) {

    const { isEdit, currentFiles, onUploadSucceeded, ...rest } = props

    const [fileStatus, setfileStatus] = useState(null)

    console.log(isEdit)

    return (
        <>
            <CarbonFileUploader
                buttonKind="primary"
                buttonLabel="رفع الملف"
                filenameStatus={fileStatus}
                accept={['jpg', 'jpeg', 'png', 'pdf', 'txt']}
                multiple
                //iconDescription="Clear file"
                labelDescription="ملفات .jpg,.jpeg,.png,.pdf,.txt فقط بحجم 4 ميغا بايت أو أقل"
                labelTitle="اضف صورة"
                onChange={(e) => {
                    var myHeaders = new Headers();
                    //myHeaders.append("Content-Type", "multipart/form-data");
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

                    var formdata = new FormData();

                    var fl = e.target.files;
                    var i = 0;

                    for(i; i < fl.length; i++) {
                        formdata.append("files[]", fl[i]);
                    }

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                    }

                    setfileStatus('uploading')
                    fetchAPIwithAuth("/media", requestOptions)
                        .then(res => {
                            setfileStatus('complete')
                            onUploadSucceeded(res.body)
                            e.target.value = null
                        })
                        .catch(error => console.log('error', error));
                }}
            />

            {(isEdit && currentFiles) && currentFiles.map(currentFile => (<FileUploaderItem
                name={currentFile?.original_name}
                status="edit"
                onDelete={() => {
                    //setcurrentFileId(null)
                    //console.log('delete fired for', currentFileId)
                }} />
            ))
            }

        </>
    )
}
