import React from 'react'

export default function AppBar({
    title,
    subtitle
}) {
    return (
        <div>
            <h2 className="bx--type-bold mb-5">
                {title}
            </h2>
            <p style={{ marginBottom: "2rem"}}>
                {subtitle}
            </p>
        </div>
    )
}
