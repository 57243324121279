import React from 'react'

import AppBar from '../../components/AppBar'
import { useLocation, useParams } from 'react-router';
import StoreForm from '../../partial/forms/store';
import StoresTable from '../../components/StoresTable';

// TODO: make it global
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Stores() {

    let query = useQuery();

    let action = query.get('action')
    const storeId = query.get('id')

    if (storeId) {
        action = 'edit';
    }

    let pageTitle = "المتاجر";

    if (action == 'add') {
        pageTitle = 'اضافة متجر'
    } else if (action == 'edit') {
        pageTitle = 'تعديل'
    }

    return (
        <>
            <AppBar title={pageTitle} />

            <div className="pt-7"></div>

            {!storeId && !action && <StoresTable />}

            {
                (action == 'edit' || action == 'add') && (
                    <StoreForm id={storeId} />
                )
            }
        </>
    )
}
