import { Form, Tab, Tabs, TextInput, Toggle, Row, Column, Button, NumberInput, SkeletonText, InlineNotification, FormGroup } from 'carbon-components-react'
import React, { useEffect, useState } from 'react'
import AppBar from '../../components/AppBar'

import { spacing } from '@carbon/layout'
import { fetchAPIwithAuth } from '../../helpers'
import { useAuth } from '../../hooks/useUser'

const AcountSettings = () => {

    const { user } = useAuth()

    const [isSaving, setIsSaving] = useState(false)

    const [form, setForm] = useState({
        email: user?.email,
        currentPwd: '',
        newPwd: '',
        confirmNewPwd: ''
    })
    const [formNotification, setformNotification] = useState(false)

    const handleFormChange = (newValue) => {
        setForm({ ...form, ...newValue })
    }

    const save = async () => {

        setIsSaving(true)

        let data = { email: form.email }

        if(form.currentPwd.length > 0 ) {
            data.currentPwd = form.currentPwd
        }

        if(form.newPwd.length > 0 ) {
            data.newPwd = form.newPwd
        }

        if(form.confirmNewPwd.length > 0 ) {
            data.confirmNewPwd = form.confirmNewPwd
        }

        return fetchAPIwithAuth('/account', {
            method: "POST",
            body: JSON.stringify(data)
        }).then(res => {

            setIsSaving(false)

            const { status, body } = res

            // Resource updated successfully
            if (status == 200) {
                return Promise.resolve({
                    notification: {
                        kind: 'success',
                        title: `تم تحديث المعطيات بنجاح`
                    }
                })
            }

            return Promise.reject({
                notification: {
                    kind: 'error',
                    title: 'حدث خطأ غير متوقع.',
                    subtitle: 'المرجو اعادة المحاولة مرة اخرى'
                }
            })

        })


    }

    return (
        <Row>

            <Column lg={6} sm={4}>


                <Form>

                    <TextInput
                        labelText="البريد الإلكتروني"
                        value={form?.email}
                        onChange={(e) => handleFormChange({ email: e.target.value })}
                        style={{ marginBottom: spacing[6] }}
                    />


                    <FormGroup
                        legendId="formgroup-legend-id"
                        legendText="كلمة المرور"
                    >
                        <TextInput
                            labelText="كلمة المرور الحالية"
                            value={form?.currentPwd}
                            type="password"
                            onChange={(e) => handleFormChange({ currentPwd: e.target.value })}
                            style={{ marginBottom: spacing[6] }}
                        />
                        <TextInput
                            value={form?.newPwd}
                            labelText="كلمة المرور الجديدة"
                            type={"password"}
                            onChange={(e) => handleFormChange({ newPwd: e.target.value })}
                            style={{ marginBottom: spacing[6] }}
                        />
                        <TextInput
                            value={form?.confirmNewPwd}
                            onChange={(e) => handleFormChange({ confirmNewPwd: e.target.value })}
                            type={"password"}
                            style={{ marginBottom: spacing[7] }}
                            labelText="تأكيد كلمة المرور"
                        />
                    </FormGroup>

                    <Button
                        disabled={isSaving || form.currentPwd.length > 0 && form?.newPwd !== form?.confirmNewPwd}
                        onClick={() => {
                            save().then((res) => {
                                setformNotification(res.notification)
                            }).catch((e) => {
                                setformNotification(e.notification)
                            })
                        }}
                    >
                        {isSaving ? "جاري الحفظ" : "حفظ البيانات"}
                    </Button>

                    {formNotification !== false && <InlineNotification
                        role='alert'
                        onClose={() => setformNotification(false)}
                        {...formNotification}
                    />
                    }

                </Form>

            </Column>
        </Row>
    )
}

const GeneralSettings = () => {

    const [isSaving, setIsSaving] = useState(false);
    const [formNotification, setformNotification] = useState(false)
    const [form, setForm] = useState();

    const handleFormChange = (key, field, value) => {
        const change = { ...form }

        if (!value) {
            change[key] = field
        } else {
            change[key][field] = value
        }

        setForm(change)
    }

    useEffect(() => {

        fetchAPIwithAuth('/settings', {
            method: "get"
        }).then((res) => {
            const { body } = res

            setForm(body)

        })

        return;
    }, [])

    const save = async () => {

        setIsSaving(true)

        let data = { ...form }

        return fetchAPIwithAuth('/settings/general_settings', {
            method: "PATCH",
            body: JSON.stringify(data)
        }).then(res => {

            setIsSaving(false)

            const { status, body } = res

            // Resource updated successfully
            if (status == 200) {
                return Promise.resolve({
                    tool: body,
                    notification: {
                        kind: 'success',
                        title: `تم تحديث المعطيات بنجاح`
                    }
                })
            }

            return Promise.reject({
                notification: {
                    kind: 'error',
                    title: 'حدث خطأ غير متوقع.',
                    subtitle: 'المرجو اعادة المحاولة مرة اخرى'
                }
            })

        })


    }

    if (!form) {
        return <SkeletonText />
    }

    return (
        <Row>

            <Column lg={6} sm={4}>

                <Form>
                    <div style={{ marginBottom: spacing[6] }}>
                        <NumberInput
                            label="نسبة الضريبة"
                            onChange={(e) => {
                                handleFormChange('tax_rate', e.imaginaryTarget.value)
                            }}
                            value={form?.tax_rate}
                        />
                    </div>
                    <TextInput
                        labelText="اسم المستفيد"
                        style={{ marginBottom: spacing[6] }}
                        value={form?.beneficiary_name}
                        onChange={(e) => {
                            handleFormChange('beneficiary_name', e.target.value)
                        }}
                    />
                    <TextInput
                        labelText="اسم الشركة"
                        style={{ marginBottom: spacing[6] }}
                        value={form?.company_name}
                        onChange={(e) => {
                            handleFormChange('company_name', e.target.value)
                        }}
                    />
                    <TextInput
                        labelText="رقم الهوية"
                        style={{ marginBottom: spacing[6] }}
                        value={form?.beneficiary_ID}
                        onChange={(e) => {
                            handleFormChange('beneficiary_ID', e.target.value)
                        }}
                    />
                    <TextInput
                        labelText="رقم الهاتف"
                        style={{ marginBottom: spacing[6] }}
                        value={form?.beneficiary_phone}
                        onChange={(e) => {
                            handleFormChange('beneficiary_phone', e.target.value)
                        }}
                    />
                    <TextInput
                        labelText="سجل التجاري"
                        style={{ marginBottom: spacing[6] }}
                        value={form?.commercial_record}
                        onChange={(e) => {
                            handleFormChange('commercial_record', e.target.value)
                        }}
                    />

                    <Button
                        disabled={isSaving}
                        onClick={() => {
                            save().then((res) => {
                                setformNotification(res.notification)
                            }).catch((e) => {
                                setformNotification(e.notification)
                            })
                        }}
                    >
                        {isSaving ? "جاري الحفظ" : "حفظ البيانات"}
                    </Button>

                    {formNotification !== false && <InlineNotification
                        role='alert'
                        onClose={() => setformNotification(false)}
                        {...formNotification}
                    />
                    }

                </Form>

            </Column>
        </Row>
    )
}

export default function Settings() {

    const { user } = useAuth()

    return (
        <>
            <AppBar title="الإعدادات" />

            <div className="pt-7"></div>

            <Tabs type="container">
                <Tab id="tab-1" label="إعدادت الحساب">
                    <AcountSettings />
                </Tab>
                {user.role != 'employee' && <Tab id="tab-2" label="إعدادات عامة">
                    <GeneralSettings />
                </Tab>}
            </Tabs>

        </>
    )
}
