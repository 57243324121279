import React from 'react'

import {
    Search20,
    Notification20,
    Add20,
    Menu20,
    AppSwitcher20,
    Logout16,
    TaskTools16,
    Dashboard16,
    Receipt16,
    ToolBox16,
    Events16,
    Settings16,
    Store16,
    Wallet16
} from "@carbon/icons-react";
import {
    Content,
    Header,
    HeaderMenuButton,
    HeaderContainer,
    HeaderName,
    HeaderGlobalBar,
    HeaderGlobalAction,
    SkipToContent,
    SideNav,
    SideNavItems,
    SideNavLink,
    SideNavMenu,
    SideNavDivider,
    SideNavMenuItem,
    Column,
    Row,
    Grid,
    SideNavItem,
    SideNavLinkText,
    SideNavIcon,
} from "carbon-components-react";
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useUser';

const links = [
    {
        text: "نظرة عامة",
        path: "/",
        icon: () => <Dashboard16 />
    },
    {
        text: "عمليات التأجير",
        path: "/dashboard/rentals",
        icon: () => <Receipt16 />
    },
    {
        text: "أعمال الصيانة",
        path: "/dashboard/maintenance",
        icon: () => <TaskTools16 />
    },
    {
        text: "المعدات",
        path: "/dashboard/tools",
        icon: () => <ToolBox16 />
    },
    {
        text: "الدفعات",
        path: "/dashboard/payments",
        icon: () => <Wallet16 />
    },
    {
        text: "الموظفين",
        path: "/dashboard/employees",
        icon: () => <Events16 />
    },
    {
        text: "المتاجر",
        path: "/dashboard/stores",
        icon: () => <Store16 />
    },
    {
        text: "الإعدادات",
        path: "/dashboard/settings",
        icon: () => <Settings16 />
    },
]

export default function DashLayout({ children }) {

    const { user } = useAuth()

    return (
        <div className="container">
            <HeaderContainer
                render={({ isSideNavExpanded, onClickSideNavExpand }) => (
                    <>
                        <Header aria-label="IBM Platform Name">
                            <HeaderMenuButton
                                aria-label="Open menu"
                                onClick={onClickSideNavExpand}
                                isActive={isSideNavExpanded}
                                isCollapsible={true}
                                renderMenuIcon={<Menu20 />}
                            />
                            <HeaderName prefix={null} href="#">
                                الوســيــــط
                            </HeaderName>
                        </Header>
                        <SideNav aria-label="Side navigation" isRail onOverlayClick={onClickSideNavExpand} isChildOfHeader={false} expanded={isSideNavExpanded}>
                            <SideNavItems>
                                {links.map(link => {
                                    if(['/dashboard/stores', '/dashboard/employees', '/dashboard/tools'].includes(link.path) && user.role == 'employee') {
                                        return;
                                    }
                                    return (<SideNavItem large>
                                        <Link to={link.path} href={link.path} className={"bx--side-nav__link"}>
                                            <SideNavIcon small>
                                                {link.icon()}
                                            </SideNavIcon>
                                            <SideNavLinkText>
                                                {link.text}
                                            </SideNavLinkText>
                                        </Link>
                                    </SideNavItem>)
                                })}

                                <SideNavDivider />

                                <SideNavItem>
                                    <Link to={{ pathname: "/logout" }} href={"#"} className="bx--side-nav__link">
                                        <SideNavIcon small>
                                            <Logout16 />
                                        </SideNavIcon>
                                        <SideNavLinkText>
                                            تسجيل الخروج
                                        </SideNavLinkText>
                                    </Link>
                                </SideNavItem>

                            </SideNavItems>

                        </SideNav>

                        <Content id="main-content">
                            <Grid>
                                <Row>
                                    <Column lg={{ offset: 0, span: 16 }} as="section">
                                        {children}
                                    </Column>
                                </Row>
                            </Grid>
                        </Content>
                    </>
                )}
            />
        </div>
    )
}
