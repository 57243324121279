import React, { useEffect, useState } from 'react'

import { Button, Column, Loading, Row } from 'carbon-components-react'

import { useQuery } from '../../hooks/useQuery'
import { fetchAPIwithAuth } from '../../helpers'
var moment = require('moment-hijri');
moment.locale('ar-ma');// 2- Set the global locale to `ar-SA`


export default function InvoicePrint() {

    let query = useQuery();
    const invoiceId = query.get('id')

    const [data, setdata] = useState([])

    useEffect(() => {
        if (invoiceId) {
            fetchAPIwithAuth('/orders/' + invoiceId, {
                method: "get"
            }).then(res => {
                setdata(res.body.data)
            })
        }
    }, [])

    const printInvoice = () => {
        window.print()
    }

    if (data.length == 0) {
        return (
            <Loading />
        )
    }

    const hijri_gergoian_effective_date = moment(data.effective_date);
    const hijri_gergoian_due_date = moment(data.due_date);

    return (
        <>
            <div id="invoice-receipt" style={{ color: "#3b3c6c", width: 700, margin: "0 auto" }}>

                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h3 style={{ color: "#f78d8d" }}>{data.id}</h3>
                    <h1 style={{ fontWeight: "bold", marginBottom: "0.5rem" }}>الوسـيـــط</h1>
                    <h4 style={{ fontWeight: 'bold' }}>جوال / <span contentEditable>{data?.beneficiary_phone}</span></h4>
                </div>
                <div style={{ border: "2px #3b3c6c solid", padding: "1rem", marginTop: 40, borderRadius: 16, fontWeight: "bold" }}>
                    <div style={{ marginBottom: "1.75rem" }}>

                        <h4 style={{ margin: "-41px auto 1.75rem auto ", background: "#3b3c6c", WebkitPrintColorAdjust: "exact", padding: "0.25rem 1.5rem", display: "table", color: "#fff", borderRadius: 8 }}>
                            سـنــد لأمـــر
                        </h4>

                        <p style={{ marginBottom: "0.5rem" }} contentEditable>مكان الإنشاء : {data?.store.address}. المبلغ ({data.amount ? data.amount : '......................'}) ريال سعودي لاغير.</p>
                        <p style={{ marginBottom: "0.5rem" }} contentEditable>تاريخ الإنشاء {hijri_gergoian_effective_date.format('iYYYY/iM/iDهـ الموافق YYYY/M/Dم')} </p>
                        <p style={{ marginBottom: "0.5rem" }} contentEditable>أتعهد بأن أدفع بموجب هذا السند لأمر مؤسسة محمد قاسم محمد مذكور</p>
                        <p style={{ marginBottom: "0.5rem" }} contentEditable>لصاحبها محمد قاسم محمد مذكور سعودي الجنسية يحمل هوية رقم / ۱۰۲۰۲۰۱۵6 (المستفيد).</p>
                        <p style={{ marginBottom: "0.5rem" }} contentEditable>المبلغ الموضح أعلاه ({data.amount ? data.amount : '......................'}) ريال سعودي لاغير.</p>
                        <p style={{ marginBottom: "0.5rem" }} contentEditable>بتاریخ استحقاق في ({hijri_gergoian_due_date.isValid() ? hijri_gergoian_due_date.format('iYYYY/iM/iDهـ الموافق YYYY/M/Dم') : '......................................'}) مكان الإستحقاق: الطائف</p>
                        <p style={{}} contentEditable>ولحامل هذه الورقة حق الرجوع بدون مصروفات أو احتجاج.</p>

                    </div>

                    <Row style={{ marginBottom: "1rem" }}>
                        <Column>
                            <h6 style={{ marginBottom: 64 }}>البصمة</h6>
                            <h6>توقيع المدين</h6>
                        </Column>
                        <Column>
                            <ul>
                                <li style={{ marginBottom: "1.5rem" }}>
                                    <span style={{ fontWeight: "bold" }}>اسم المدين/ {data.tenant_name}</span>
                                </li>
                                <li style={{ marginBottom: "1.5rem" }}>
                                    <span style={{ fontWeight: "bold" }}> الجنسية: {data.tenant_nationality}</span>
                                </li>
                                <li style={{ marginBottom: "1.5rem" }}>
                                    <span style={{ fontWeight: "bold" }}>العنوان: {data.tenant_address} </span>
                                </li>
                                <li style={{ marginBottom: "1.5rem" }}>
                                    <span style={{ fontWeight: "bold" }}>رقم الهوية/الإقامة: {data.tenant_ID_Residency_number}</span>
                                </li>
                                <li style={{ marginBottom: "1.5rem" }}>
                                    <span style={{ fontWeight: "bold" }}>الجوال: {data.tenant_phone}</span>
                                </li>
                            </ul>

                        </Column>
                    </Row>

                    <h5 style={{ margin: "1rem auto 0 auto ", background: "#3b3c6c", WebkitPrintColorAdjust: "exact", padding: "0.25rem 1.5rem", display: "table", color: "#fff", borderRadius: 8 }}>
                        اتعاب المحاماة على المدين في حال عدم السداد
                    </h5>

                </div>

            </div>
            <div style={{display:"flex", justifyContent:"center", marginTop: "32px"}} >
                <Button onClick={() => printInvoice()} className='print-btn'>طباعة</Button>
            </div>
        </>
    )
}
