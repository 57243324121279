import React from 'react'
import { Button, TextInput, Grid, Row, Column, Form } from 'carbon-components-react'
import { useAuth } from '../hooks/useUser'
import LoginForm from '../partial/forms/login';

export default function Login() {
    
    return (
        <div className="d-table position-absolute w-100 h-100">
            <div className="align-middle d-table-cell">
                <Grid>
                    <Row className="bx--row justify-content-center">
                        <Column className="bx--col">
                            <div id="login-box" className="mx-auto p-6">
                                <div className="text-center">
                                    <h1 className="bx--type-display-02 mb-6">
                                        الوسيط
                                    </h1>
                                    <p className="bx--type-expressive-heading-03">
                                        لتأجير المعدات الإنشائية والسقالات والجاكات والأخشاب
                                    </p>
                                </div>

                                <div className="py-6"></div>

                                <LoginForm />

                            </div>
                        </Column>
                    </Row>
                </Grid>
            </div>
        </div>

    )
}
